/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShowEToast = (e) => {
  toast.error(`${e}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ShowSToast = (m) => {
  toast.success(`${m}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const modifiederror = (e) => {
  if (e.message === "Request failed with status code 401") {
    return "No user with the provided credentials was found. Please check email and passsword again";
  }
  if (e.message === "Network Error") {
    return "Seems like you are not connected to the internet";
  }
  if (e.message === "Request failed with status code 500") {
    return "Something went wrong. Please try again later";
  }
  if (e.message === "Request failed with status code 400") {
    return "Invalid Email Address";
  }
};

export const completeRegistration = createAsyncThunk(
  "tsc/completeRegistration",
  async (values, thunkAPI) => {
    try {
     
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/create/MemberAccountDetail/v1",
        {
          ...values.values,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,

            // "X-CSRFToken": token,

            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      // console.log('[complete tsc response]', response.data);
      localStorage.setItem("account", JSON.stringify(response.data.account));
      /* const p_info = JSON.parse(localStorage.getItem("personalInfo")) ?? {};
      const newP_info = { ...p_info, member_status: 1 };
      localStorage.removeItem("personalInfo");
      localStorage.setItem("personalInfo", JSON.stringify(newP_info));*/
      values.moveToLanding();
      ShowSToast("Account Registration successfull");
      return response.data;
    } catch (e) {
      console.log("[complete info Error]", e.message);
      console.log({ ...e });
      thunkAPI.rejectWithValue(e.response.data.message);
      const es = modifiederror(e);
      if (e.response.data.message) ShowEToast(e.response?.data?.message);
      else ShowEToast(e.message);
      return e.message;
    }
  }
);

export const TscSlice = createSlice({
  name: "tsc",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    personalInfo: {},
    employmentInfo: {},
    nextOfKinInfo: [],
    remittanceinfo: {},
    data: [],
  },

  reducers: {
    addUserInfo: (state, action) => {
      // console.log('personal info', action.payload)
      state.personalInfo = action.payload.data;
      // localStorage.removeItem('tscPersonalinfo')
      localStorage.setItem(
        "tscPersonalinfo",
        JSON.stringify(action.payload.data1)
      );
      action.payload.moveToNext(1);
      return state;
    },

    addEmploymentInfo: (state, action) => {
      // console.log('employment info', action.payload)
      state.employmentInfo = action.payload.data;
      localStorage.setItem(
        "tscEmploymentinfo",
        JSON.stringify(action.payload.data)
      );
      action.payload.moveToNext(2);
      return state;
    },

    addNextOfKin: (state, action) => {
      state.nextOfKinInfo = action.payload.data;
      localStorage.setItem(
        "tscnextofkininfo",
        JSON.stringify(action.payload.data)
      );
      action.payload.moveToNext(3);
      // action.payload.moveToNext(2);
      return state;
    },
    addRemittance: (state, action) => {
      state.remittanceinfo = action.payload;
      localStorage.setItem("tscRemitanceInfo", action.payload);
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(completeRegistration.pending, (state) => {
      state.isFetching = true;
      return state;
    }),
      builder.addCase(completeRegistration.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(completeRegistration.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload;
        return state;
      });
  },
});

export const {
  addUserInfo,
  addNextOfKin,
  addRemittance,
  addEmploymentInfo,
} = TscSlice.actions;

export const tscSelector = (state) => state.tsc;
