/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../axios";
import { ShowEToast } from "../../context/compressor";

export const getAccountTypes = createAsyncThunk(
  "prods/getAccountTypes",
  async (values, thunkAPI) => {
    try {
      const response = await axiosClient.get("/get/account-types/all/v1", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log('[accounttypes response]', response.data.data);
      return response.data.data;
    } catch (e) {
      console.log("[get accounttypes Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      ShowEToast(e.response.data.message);
      return e.message;
    }
  }
);

export const productApplication = createAsyncThunk(
  "prods/productApplication",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/add_application_request/v1",
        { ...values.data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      // console.log('[application response]', response.data);
      values.moveToSuccess();
      return response.data;
    } catch (e) {
      console.log("[submit application form info Error]", { ...e });
      thunkAPI.rejectWithValue(e.message);
      if (e?.response?.data?.message) ShowEToast(e.response.data.message);
      else ShowEToast(e.response.statusText + "Try again later.");
      return e.message;
    }
  }
);

export const getSubAccounts = createAsyncThunk(
  "prods/subaccounsn",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/add_application_request/v1",
        { ...values.data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      // console.log('[application response]', response.data);
      values.moveToSuccess();
      return response.data;
    } catch (e) {
      console.log("[submit application form info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      ShowEToast(e.response.data.message);
      return e.message;
    }
  }
);

export const queryDividends = createAsyncThunk(
  "prods/queryDividends",
  async (value, thunkAPI) => {
    try {
      // localStorage.removeItem('dividends')
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/get/user/dividents/v1",
        { user_id: value.id, account_id: value.account_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );

      // console.log('[dividends response]', response.data);
      localStorage.removeItem("dividends");
      if (response.data.length) {
        //localStorage.setItem('dividends', JSON.stringify(response.data));
        value.setPayload(response.data);
      }

      // values.moveToSuccess()
      return response.data;
    } catch (e) {
      console.log("[dividends info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return e.message;
    }
  }
);

export const AccountTypeSlice = createSlice({
  name: "prods",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    user: {},
    accountTypesData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountTypes.pending, (state) => {
      state.isFetching = true;
      return state;
    }),
      builder.addCase(getAccountTypes.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.accountTypesData = payload;
        return state;
      }),
      builder.addCase(productApplication.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(productApplication.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(productApplication.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.accountTypesData = payload;
        return state;
      }),
      builder.addCase(queryDividends.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(queryDividends.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(queryDividends.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        // state.accountTypesData = payload;
        return state;
      });
  },
});

export const accounttypeSelector = (state) => state.prods;
