import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import homeIcon from "../images/icons/homevector.svg";

export default function BreadCrumbRenderer({
  links = [{ name: "Home", href: "/" }],
}) {
  // set the web page title
  let lastItem = links[links.length - 1];
  document.title = `Mentor Sacco -  ${
    links.length > 1 ? lastItem?.name : links?.[0].name
  }`;

  return (
    <>
      <div className="line"></div>
      <nav className="container breadcrumb-div mt-1r" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <img
            loading="lazy"
            src={homeIcon}
            alt=""
            style={{ marginRight: 5, width: "20px", height: "20px" }}
          />
          <li className="breadcrumb-item">
            <a href="/" className="breadcrumb-item-override">
              Home
            </a>
          </li>
          {links.length > 1 ? (
            <RenderMultiCrumbs links={links} />
          ) : (
            <>
              <li
                className="breadcrumb-item cursor-pointer active"
                aria-current="page"
              >
                <span className="breadcrumb-item-override-active">
                  {links?.[0].name}
                </span>
              </li>
            </>
          )}
        </ol>
      </nav>
      <div className="line"></div>
    </>
  );
}

const RenderMultiCrumbs = ({ links }) => {
  let lastItem = links[links.length - 1];
  const history = useHistory();
  const handleHistory = (link) => {
    const backLinks = ["/solution-details"];
    if (backLinks.includes(link.href)) history.goBack();
    else return false;
  };

  return links.map((link) =>
    link.name === lastItem.name ? (
      <li
        className="breadcrumb-item active"
        key={link.name}
        aria-current="page"
      >
        <span className="breadcrumb-item-override-active">{link.name}</span>
      </li>
    ) : (
      <li
        className="breadcrumb-item cursor-pointer"
        onClick={() => handleHistory(link)}
        key={link.name}
      >
        {link.href === "/solution-details" ? (
          <span>{link.name}</span>
        ) : (
          <a
            href={link.href}
            className="breadcrumb-item-override cursor-pointer"
          >
            {link.name}
          </a>
        )}
      </li>
    )
  );
};
//{link.href}
