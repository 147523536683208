/* eslint-disable no-unused-expressions */

/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { compressImgUrl } from "../../context/compressor";
// import { useHistory } from 'react-router-dom';
// import axios from 'axios';

const ShowEToast = (e) => {
  toast.error(`${e}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ShowSToast = (m) => {
  toast.success(`${m}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const modifiederror = (e) => {
  if (e.message === "Request failed with status code 401") {
    return "No user with the provided credentials was found. Please check email and passsword again";
  }
  if (e.message === "Network Error") {
    return "Seems like you are not connected to the internet";
  }
  if (e.message === "Request failed with status code 500") {
    return "An error occured. Please try again later";
  }
  if (e.message === "Request failed with status code 400") {
    return "Invalid Email Address";
  }
};

const modifiederror1 = (e) => {
  if (e.message === "Request failed with status code 401") {
    return "No user with the provided credentials was found. Please check email and passsword again";
  }
  if (e.message === "Network Error") {
    return "Seems like you are not connected to the internet";
  }
  if (e.message === "Request failed with status code 500") {
    return "There is a user with specified Email address";
  }
  if (e.message === "Request failed with status code 400") {
    return "Invalid Email Address";
  }
};

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (values, thunkAPI) => {
    // console.log('reg values', values)
    let payload = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      username: values.username,
      phone_number: values.phoneNumber,
      password: values.password,
      account_type: values.accountType,
      gender_id: values.gender,
    };
    if ("user_id" in values) payload.user_id = values.user_id;
    try {
      //const response = await axios.post('https://demo-mentorproject.herokuapp.com/api/add/User/Record/v1',
      const response = await axiosClient.post(
        "/add/User/Record/v1",
        payload
        // {
        //   headers: {
        //     'Access-Control-Allow-Origin': "*"
        //   }
        // }
      );

      let { data } = response;
      console.log("[Sign up response]", data);

      // values.moveToLogin()
      localStorage.setItem("token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("account", JSON.stringify(data.account));
      ///localStorage.setItem("personalInfo", JSON.stringify(data.personal_info));
      // localStorage.setItem('registrationStatus', 0)
      values?.resetForm();
      values.moveToLanding();
      ShowSToast("Account registration successful.");
      return response.data;
    } catch (e) {
      console.log("[Sign up Error]", e.message);
      const es = modifiederror1(e);
      // vibrate for phone
      window.navigator.vibrate([200, 100, 200]);
      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else if (e.request) {
        ShowEToast(e.response.data.detail);
      } else {
        const es = modifiederror(e);
        ShowEToast(es);
      }
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (values, thunkAPI) => {
    // console.log('[values sent to login function]', values);
    try {
      let payload;
      const firstTimer = values.first_login;
      if (firstTimer) {
        payload = {
          first_login: 1,
          username: values.phone_number,
          ///username: "first_time_login",
          // password: "first_time_login",
        };
      } else {
        payload = {
          username: values.username,
          password: values.password,
        };
      }

      const response = await axiosClient.post(
        "/login/v1",
        payload
        // {
        //   headers: {
        //     'Access-Control-Allow-Origin': "*"
        //   }
        // }
      );

      if (firstTimer) {
        const {
          data: { user, account_type },
        } = response;
        if (Array.isArray(user)) {
          ShowEToast(
            "Information not found for first time login. Try logging in here instead"
          );
          values.handleBack();
        } else {
          // if he/or she is an existing member, send their user id back
          if (user?.personal_info?.first_login === true) {
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("accountType", JSON.stringify(account_type));
            // send them to otp section
            values.showFirstLoginOTP("");
          } else {
            ShowEToast(
              "Looks like this is your first time login. Try logging in here instead"
            );
            values.handleBack(); // if they are not first timers, just send them to login page and wipe local storage
          }
        }
      } else {
        if ("detail" in response.data) {
          throw new Error(response.data.detail);
        } else {
          const {
            data: { access, refresh },
          } = response;
          // console.log('[Login response]', data);

          // first clear local storage
          localStorage.clear();
          localStorage.setItem("token", access);
          localStorage.setItem("refresh_token", refresh);
          values.moveToOtp();
        }
      }

      return response.data;
    } catch (e) {
      console.log("[Login Error]", e.message, { ...e });
      // vibrate for phone
      window.navigator.vibrate([200, 100, 200]);
      if (!e.response) {
        ShowEToast(e.message);
      } else {
        ShowEToast(e.message);
      }
      //const es = modifiederror(e);

      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
export const fetchUserByToken = createAsyncThunk(
  "auth/fetchUserByToken",
  async (tokens, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.get("/get/user", {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Access-Control-Allow-Origin': "*"
        },
      });
      let { data: user } = response;

      // compress images for users already on the system else just add them  to LS

      if (user?.id < 500 && !!user?.personal_info?.photo_url) {
        // this callback function targets user object

        compressImgUrl(
          user?.personal_info?.photo_url,
          user,
          (newImage, userObj, error = false) => {
            const userPayload = {
              ...userObj,
              lastLogin: Date.now(),
              personal_info: {
                ...userObj.personal_info,
                photo_url: error ? null : newImage,
              },
            };

            localStorage.setItem("user", JSON.stringify(userPayload));
          }
        );
      } else {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, lastLogin: Date.now() })
        );
      }

      // console.log('[fetch user response]', data);
    } catch (e) {
      console.log("[fetch user Error]", e.message);
      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else {
        const es = modifiederror(e);
        ShowEToast(es);
      }
      thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      let payload = { otp: values.otp };
      // console.log('token', token)
      const response = await axiosClient.post("/validate/OTPCode/v1", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Access-Control-Allow-Origin': "*"
        },
      });

      localStorage.setItem(
        "account",
        JSON.stringify(response.data.account_info)
      );
      localStorage.setItem(
        "accountType",
        JSON.stringify(response.data.account_type)
      );

      // vibrate for phone
      window.navigator.vibrate([200, 100, 200]);
      values.moveToLanding();
      return response.data;
    } catch (e) {
      console.log("[otp verification Error]", e.message);

      localStorage.removeItem("token");

      // vibrate for phone
      window.navigator.vibrate([200, 100, 200]);
      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else {
        const es = modifiederror(e);
        ShowEToast(e.message);
      }
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const editUserProfile = createAsyncThunk(
  "auth/editProfile",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/edit/user/profile/v1",
        { ...values.data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      //console.log("[edit response]", response.data);
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(response.data));

      ShowSToast("Profile updated succcessfully");
      return response.data;
    } catch (e) {
      console.log("[edit Error axios]", { ...e });
      const es = modifiederror(e);
      if (e.response) {
        ShowEToast(e?.response?.data?.message);
      } else {
        const es = modifiederror(e);
        ShowEToast(es);
      }
      return thunkAPI.rejectWithValue(e.message);
      // localStorage.removeItem('token')
      // return e.message
    }
  }
);

export const changePasswordProfile = createAsyncThunk(
  "auth/changePasswordProfile",
  async (values, thunkAPI) => {
    try {
      values.passwordFeed({ pwordLoading: true });
      const token = localStorage.getItem("token");

      const response = await axiosClient.post(
        "/change_password",
        { password: values.password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      //console.log("[edit resp]", response.data);
      values.passwordFeed({ pwordLoading: false });
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(response.data));
      ShowSToast(response.data.message);
      return response.data;
    } catch (e) {
      values.passwordFeed({ pwordLoading: false });
      console.log("[edit Error]", e.message, { ...e });
      // vibrate for phone
      window.navigator.vibrate([200, 100, 200]);
      if (e.response) {
        ShowEToast(e?.response?.data?.message);
      } else {
        const es = modifiederror(e);
        ShowEToast(e.message);
      }

      return thunkAPI.rejectWithValue(e.message);
      // localStorage.removeItem('token')
      // return e.message
    }
  }
);

export const sendResetLink = createAsyncThunk(
  "auth/sendResetLink",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post("/confirm/account-present", {
        ...values.data,
      });
      console.log("[link resp]", response.data);
      // localStorage.removeItem('user')
      // localStorage.setItem('user', JSON.stringify(response.data))
      values.goToResetPage(response.data);
      return response.data;
    } catch (e) {
      console.log("[edit Error]", e.message);
      const es = modifiederror(e);

      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else {
        const es = modifiederror(e);
        ShowEToast(e.message);
      }
      return thunkAPI.rejectWithValue(e.message);
      // localStorage.removeItem('token')
      // return e.message
    }
  }
);

export const saveNewPassword = createAsyncThunk(
  "auth/saveNewPassword",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post("/reset/user-password", {
        ...values.data,
      });
      console.log("[link resp]", response.data);
      // localStorage.removeItem('user')
      // localStorage.setItem('user', JSON.stringify(response.data))
      ShowSToast("Password changed successfully.... redirecting");
      setTimeout(() => values.goToLogin(), 2000);
      return response.data;
    } catch (e) {
      console.log("[edit Error]", { ...e });
      const es = modifiederror(e);
      // vibrate for phone
      window.navigator.vibrate([200, 100, 200]);
      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else {
        const es = modifiederror(e);
        ShowEToast(es);
      }
      return thunkAPI.rejectWithValue(e.message);
      // localStorage.removeItem('token')
      // return e.message
    }
  }
);

export const checkExistingMember = createAsyncThunk(
  "auth/checkexisting",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post("/reseet/user-password", {
        member_number: values.memberNo,
      });
      console.log("[link resp]", response.data);

      values.moveToExistingPasswordReset();
      return response.data;
    } catch (e) {
      console.log("[edit Error]", e.message);
      const es = modifiederror(e);
      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else {
        const es = modifiederror(e);
        ShowEToast(es);
      }
      values.moveToExistingPasswordReset();
      return thunkAPI.rejectWithValue(e.message);
      // localStorage.removeItem('token')
      // return e.message
    }
  }
);
export const addExistingMember = createAsyncThunk(
  "auth/addexisting",
  async (values, thunkAPI) => {
    console.log(values);
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post("/reseet/user-password", {
        otp: values.otp,
        password: values.password,
      });
      console.log("[link resp]", response.data);
      // localStorage.removeItem('user')
      // localStorage.setItem('user', JSON.stringify(response.data))
      values.handleSuccess(response.data);
      return response.data;
    } catch (e) {
      console.log("[edit Error]", e.message);
      values.handleSuccess(e.message);
      const es = modifiederror(e);
      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else {
        const es = modifiederror(e);
        ShowEToast(es);
      }
      return thunkAPI.rejectWithValue(e.message);
      // localStorage.removeItem('token')
      // return e.message
    }
  }
);

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    personalInfo: {},
    user: {},
    account: {},
    accountType: {},
    resetData: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    logoutStateClear: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.user = {};
      state.account = {};
      state.personalInfo = {};
    },
    getCurrentUser: (state) => {
      const user_ = JSON.parse(localStorage.getItem("user"));
      const storedUser =
        JSON.parse(localStorage.getItem("user"))?.user ?? user_;
      if (storedUser) {
        state.user = storedUser;
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(registerUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(registerUser.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload.user;
        state.personalInfo = payload.personal_info;
        state.account = payload.account;
        state.errorMessage = "";
        return state;
      }),
      builder.addCase(loginUser.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(loginUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(loginUser.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        //state.user = payload;
        //state.personalInfo = payload.personal_info;
        state.errorMessage = "";
        return state;
      }),
      builder.addCase(fetchUserByToken.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.username = payload;
        state.email = payload;
        return state;
      }),
      builder.addCase(fetchUserByToken.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(fetchUserByToken.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(editUserProfile.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(editUserProfile.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(editUserProfile.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        // state.user = payload.user;
        // state.personalInfo = payload.personal_info;
        // state.accountType = payload.account_type;
        // state.account = payload.account_info;
        return state;
      }),
      builder.addCase(changePasswordProfile.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(changePasswordProfile.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(changePasswordProfile.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        // state.user = payload.user;
        // state.personalInfo = payload.personal_info;
        // state.accountType = payload.account_type;
        // state.account = payload.account_info;
        return state;
      }),
      builder.addCase(sendResetLink.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(sendResetLink.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(sendResetLink.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        // state.user = payload.user;
        // state.personalInfo = payload.personal_info;
        // state.accountType = payload.account_type;
        // state.account = payload.account_info;
        state.resetData = payload;
        return state;
      }),
      builder.addCase(saveNewPassword.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(saveNewPassword.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(saveNewPassword.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        // state.user = payload.user;
        // state.personalInfo = payload.personal_info;
        // state.accountType = payload.account_type;
        // state.account = payload.account_info;
        return state;
      }),
      builder.addCase(verifyOTP.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(verifyOTP.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(verifyOTP.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload.user;
        state.personalInfo = payload.personal_info;
        state.accountType = payload.account_type;
        state.account = payload.account_info;
        state.errorMessage = "";
        return state;
      }),
      // checking existing member
      builder.addCase(checkExistingMember.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(checkExistingMember.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(checkExistingMember.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload.user;
        state.personalInfo = payload.personal_info;
        state.accountType = payload.account_type;
        state.account = payload.account_info;
        state.errorMessage = "";
        return state;
      }),
      // checking existing member
      builder.addCase(addExistingMember.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(addExistingMember.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
        return state;
      }),
      builder.addCase(addExistingMember.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload.user;
        state.personalInfo = payload.personal_info;
        state.accountType = payload.account_type;
        state.account = payload.account_info;
        state.errorMessage = "";
        return state;
      });
  },
});

export const {
  clearState,
  logoutStateClear,
  getCurrentUser,
} = AuthSlice.actions;

export const authSelector = (state) => state.auth;
