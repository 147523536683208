import axios from "axios";
import React, { useState } from "react";
import { NetworkOff } from "./compressor";

const AccountContext = React.createContext({
  KraCertificate: "",
  setKraCertificate: "",
  IDBack: "",
  setIDBack: "",
  photo: "",
  setPhoto: "",
  signature: "",
  setSignature: "",
  IDFront: "",
  setIDFront: "",
  IdNumber: "",
  setIdNumber: "",
  payslip: "",
  setPayslip: "",
  empLetter: "",
  setEmpLetter: "",
});
export const useAccountContext = () => React.useContext(AccountContext);

export default function AccountCreationContextProvider({ children }) {
  const storedInfo = JSON.parse(localStorage.getItem("tscPersonalinfo"));
  const user = JSON.parse(localStorage.getItem("user"))?.user;
  const _user = user ?? JSON.parse(localStorage.getItem("user"));
  // groups
  const [userInfoArray, setUserInfoArray] = useState([]);
  const [admin, setAdmin] = React.useState(false);
  // tsc
  const [KraCertificate, setKraCertificate] = useState("");
  const [IDBack, setIDBack] = useState("");
  const [photo, setPhoto] = useState("");
  const [signature, setSignature] = useState("");
  const [IDFront, setIDFront] = useState("");
  const [IdNumber, setIdNumber] = useState(
    storedInfo
      ? storedInfo?.id_number
      : _user?.personal_info?.id_number
      ? _user?.personal_info?.id_number
      : ""
  );
  const [payslip, setPayslip] = useState("");
  const [empLetter, setEmpLetter] = useState("");
  // remittance tsc
  const [remittanceMode, setRemittanceMode] = useState("");
  const [monthlyDeposit, setMonthlyDeposit] = useState("");
  // const [monthlyShare, setMonthlyShare] = useState('')
  const [mpesaCode, setMpesaCode] = useState("");
  const [enrollChecked, setEnrollChecked] = useState(false);
  const [visaChecked, setVisaChecked] = useState(false);
  const [visampesaCode, setVisaMpesaCode] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [getstk, setGetStk] = useState(false);
  const [visaStk, setVisaStk] = useState(false);
  const [visaValidateCode, setVisaValidateCode] = useState(null);
  const [visaGetconfirmation, setVisaGetconfirmation] = useState(false);
  const [visaPaymentSuccess, setVisaPaymentSuccess] = useState(false);
  const [getconfirmation, setGetconfirmation] = useState(false);
  const [validateCode, setValidateCode] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [branch, setBranch] = React.useState(0);
  const [subs, setSubs] = React.useState([]);
  // groups
  const [groupCert, setGroupCert] = useState("");
  const [groupKraPin, setGroupKraPin] = useState("");

  const [groupKracertificate, setgroupKraCertificate] = useState("");
  const [groupMinutes, setGroupminutes] = useState("");
  const groupMembers = {
    userInfoArray,
    setUserInfoArray,
    groupCert,
    setGroupCert,
    groupKracertificate,
    setgroupKraCertificate,
    groupMinutes,
    setGroupminutes,
    groupKraPin,
    setGroupKraPin,
    admin,
    setAdmin,
  };

  const tscRemittance = {
    remittanceMode,
    setRemittanceMode,
    monthlyDeposit,
    setMonthlyDeposit,
    mpesaCode,
    setMpesaCode,
    enrollChecked,
    setEnrollChecked,
    visaChecked,
    setVisaChecked,
    visampesaCode,
    setVisaMpesaCode,
    termsChecked,
    setTermsChecked,
    phoneNo,
    setPhoneNo,
    getstk,
    setGetStk,
    visaGetconfirmation,
    setVisaGetconfirmation,
    visaPaymentSuccess,
    setVisaPaymentSuccess,
    visaStk,
    setVisaStk,
    visaValidateCode,
    setVisaValidateCode,
    getconfirmation,
    setGetconfirmation,
    validateCode,
    setValidateCode,
    paymentSuccess,
    setPaymentSuccess,
    branch,
    setBranch,
    subs,
    setSubs,
  };
  const provisions = {
    KraCertificate,
    setKraCertificate,
    IDBack,
    setIDBack,
    photo,
    setPhoto,
    signature,
    setSignature,
    IDFront,
    setIDFront,
    IdNumber,
    setIdNumber,
    payslip,
    setPayslip,
    empLetter,
    setEmpLetter,
  };
  let timer;
  let counter = 0;

  const callEverySecond = async () => {
    const url = process.env.REACT_APP_BASE_URI;

    try {
      const response = await axios.get(url);

      if (response.status > 200) {
        console.info("Check network try over 200");
        console.info(response.status);
        //NetworkOff("Your internet connection is unstable");
      }
    } catch (error) {
      console.log({ ...error });
      //NetworkOff("Your internet connection is unstable");
    }
    counter++;
    timer = setTimeout(() => callEverySecond(), 1000);
  };
  React.useEffect(() => {
    //callEverySecond();
    console.log("context...");
    return () => {
      // clearTimeout(timer);
    };
  }, []);

  return (
    <AccountContext.Provider
      value={{ provisions, tscRemittance, groupMembers }}
    >
      {children}
    </AccountContext.Provider>
  );
}
