import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import "bootstrap";

import ErrorBoundary, { FallBack } from "./ErrorBoundary";
import Loading, { SmallerLoader } from "./components/Loader";
import CookieConsentComponent from "./CookieConsent";
import ChatBotComponent from "./ChatBotComponent";
import AnalyticsContextComponent from "./context/analytics.context";

/* Using React lazy to  lazy load all components, this way it reduces bundle size and improve performance  */
const ToastContainerComponent = React.lazy(() => import("./ToastContainer"));
const FAQs = React.lazy(() => import("./Pages/FAQs"));
const ImageGallery = React.lazy(() => import("./Pages/ImageGallery"));
const Footer = React.lazy(() => import("./components/Navigation/Footer"));
const AboutUs = React.lazy(() => import("./Pages/AboutUs"));
// const Dividends =React.lazy(()=>import( './Pages/Dividends/Dividends'));
const EventList = React.lazy(() => import("./Pages/Events/EventList"));
const EventDetails = React.lazy(() => import("./Pages/Events/EventDetails"));
const Home = React.lazy(() => import("./Pages/Home"));
const Location = React.lazy(() => import("./Pages/Location"));
const Memberships = React.lazy(() => import("./Pages/Memberships"));
const Personal = React.lazy(() => import("./Pages/Menu/Personal"));
const NewsList = React.lazy(() => import("./Pages/News/NewsList"));
// const InsureList =React.lazy(()=>import( './Pages/ProductPages/InsureList'));
// const InvestList =React.lazy(()=>import( './Pages/ProductPages/InvestList'));
// const LoanDetail =React.lazy(()=>import( './Pages/ProductPages/LoanDetail'));
const LoanEstimate = React.lazy(() =>
  import("./Pages/ProductPages/LoanEstimate")
);
// const LoansList =React.lazy(()=>import( './Pages/ProductPages/LoansList'));
// const SavingsList =React.lazy(()=>import( './Pages/ProductPages/SavingsList'));
const Solutions = React.lazy(() => import("./Pages/Solutions"));
const RegisterSuccess = React.lazy(() =>
  import("./Pages/SuccessPages/RegisterSuccess")
);
const Confirmation = React.lazy(() => import("./Pages/Users/Confirmation"));
const MemberLogin = React.lazy(() => import("./Pages/Users/MemberLogin"));
// const BankingList =React.lazy(()=>import( './Pages/ProductPages/BankingList'));
const NewsDetail = React.lazy(() => import("./Pages/News/NewsDetail"));
const PressReleaseComponent = React.lazy(() =>
  import("./Pages/News/PressReleas")
);
// const TSC =React.lazy(()=>import( './Pages/Memberships/TSC'));
const TermsConditions = React.lazy(() => import("./Pages/TermsConditions"));
const Business = React.lazy(() => import("./Pages/Menu/Business"));
const Corporate = React.lazy(() => import("./Pages/Menu/Corporate"));
const PersonalLoans = React.lazy(() =>
  import("./Pages/ProductCategories/Loans/PersonalLoans")
);
const BusinessLoans = React.lazy(() =>
  import("./Pages/ProductCategories/Loans/BusinessLoans")
);
const CorporateLoans = React.lazy(() =>
  import("./Pages/ProductCategories/Loans/CorporateLoans")
);
const UserProfile = React.lazy(() => import("./Pages/Profile/UserProfile"));
const MobileBanking = React.lazy(() =>
  import("./Pages/RegistrationForms/MobileBanking")
);
const ATMApplication = React.lazy(() =>
  import("./Pages/RegistrationForms/ATMApplication")
);
const FosaLoan = React.lazy(() => import("./Pages/RegistrationForms/FosaLoan"));
const BosaLoan = React.lazy(() => import("./Pages/RegistrationForms/BosaLoan"));
const FundsTransfer = React.lazy(() =>
  import("./Pages/RegistrationForms/FundsTransfer")
);
const SalaryAdvance = React.lazy(() =>
  import("./Pages/RegistrationForms/SalaryAdvance")
);
const StandingOrderVariation = React.lazy(() =>
  import("./Pages/RegistrationForms/StandingOrderVariation")
);
const AccountStatementRequest = React.lazy(() =>
  import("./Pages/RegistrationForms/AccountStatementRequest")
);
const JointAccountApplication = React.lazy(() =>
  import("./Pages/RegistrationForms/JointAccountApplication")
);
const BusinessAccountRegistration = React.lazy(() =>
  import("./Pages/RegistrationForms/BusinessAccountRegistration")
);
const OurTeam = React.lazy(() => import("./Pages/OurTeam"));
const JoinUsPage = React.lazy(() => import("./Pages/JoinUsPage"));
const TSCProfile = React.lazy(() =>
  import("./components/AccountDetails/tsc/tsc")
);
const BuisnessProfile = React.lazy(() =>
  import("./components/AccountDetails/buisness/buisness")
);
const EmployedProfile = React.lazy(() =>
  import("./components/AccountDetails/employeed/employed")
);
const GroupsProfile = React.lazy(() =>
  import("./components/AccountDetails/groups/groups")
);
// const InsuaranceDetails =React.lazy(()=>import( './Pages/ProductPages/InsuaranceDetails'));
const LandingPage = React.lazy(() => import("./Pages/Profile/LandingPage"));
const Careers = React.lazy(() => import("./Pages/Careers"));
const LoanForm = React.lazy(() => import("./Pages/RegistrationForms/LoanForm"));
// const SavingsProductDetails =React.lazy(()=>import( './Pages/ProductPages/SavingsProductDetails'));
const PrivateRoute = React.lazy(() => import("./helpers/PrivateRoute"));
// const InvestDetails =React.lazy(()=>import( './Pages/ProductPages/InvestDetails'));
const EditProfile = React.lazy(() => import("./Pages/Profile/EditProfile"));
const ShareApplication = React.lazy(() =>
  import("./Pages/RegistrationForms/ShareApplication")
);
const MainProductPage = React.lazy(() =>
  import("./Pages/ProductPages/MainProductPage")
);
const ProductDetailPage = React.lazy(() =>
  import("./Pages/ProductPages/ProductDetailPage")
);
const ProductInformation = React.lazy(() =>
  import("./Pages/ProductPages/ProductInformation")
);

const Gallery = React.lazy(() => import("./Pages/Gallery"));
const NotFound = React.lazy(() => import("./helpers/NotFound"));
const Downloads = React.lazy(() => import("./Pages/Downloads"));
const ContactUs = React.lazy(() => import("./Pages/ContactUs"));

const ResetPassword = React.lazy(() => import("./Pages/Users/ResetPassword"));
const JuniorAccountApplication = React.lazy(() =>
  import("./Pages/RegistrationForms/JunoirAccount")
);
const HolidayAccountApplication = React.lazy(() =>
  import("./Pages/RegistrationForms/HolidayAccount")
);
const FixedDAccountApplication = React.lazy(() =>
  import("./Pages/RegistrationForms/FixedDeposit")
);
if (process.env.NODE_ENV !== "development") {
  // sentry
  Sentry.init({
    dsn:
      "https://a3d889574b13ffdaa23cd52e3e907f12@o4505764794597376.ingest.sentry.io/4505764798464000",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  // delay showing cookie and chat components
  const [chatbotcookie, setChatbot] = React.useState(false);
  const [showcookie, setCookie] = React.useState(false);
  const trackingId = "UA-222118615-1";
  // "UAG4a-GA4";

  //G-6E29E307Z9
  // 305912365

  const checkCookie = (accepted = false) => {
    let consent = "cookieconsent";
    let cookie = localStorage.getItem(consent);
    if (accepted) {
      localStorage.setItem(consent, "accepted");
      setCookie(true);
      return false;
    }
    //if (cookie === null) setCookie(true);
  };
  const creatG4PageViews = () => {
    let domain = "mentorsacco";
    if (window && window.location.origin.includes(domain)) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", process.env.REACT_APP_GA4);
      gtag("event", "page_view", {
        page_title: document.title,
        page_location: window.location.pathname,
      });
    }
  };

  useEffect(() => {
    let timeout;
    creatG4PageViews();
    if (process.env.NODE_ENV !== "development") {
      ReactGA.initialize(process.env.REACT_APP_ANALTICS_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
      // show cookie and chatboot components after 3 seconds, lazy loading

      timeout = setTimeout(() => setChatbot(true), 3000);
    }
    timeout = setTimeout(() => setChatbot(true), 3000);
    return () =>
      //{
      clearTimeout(timeout);
  }, []);

  // check device,to determine the spinner to show
  const docWidth = document.documentElement.clientWidth < 768;

  return (
    <div className="main">
      <AnalyticsContextComponent>
        <ErrorBoundary fallback={FallBack}>
          <React.Suspense
            fallback={
              docWidth ? (
                <SmallerLoader message="Getting content..." />
              ) : (
                <Loading message="Getting content..." />
              )
            }
          >
            <Router>
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/services" component={Solutions} exact />
                <Route path="/memberships" component={Memberships} exact />
                <Route exact path="/joinas/:id" component={JoinUsPage} />
                <Route path="/about-us" component={AboutUs} exact />
                <Route exact path="/downloads" component={Downloads} />

                <PrivateRoute
                  exact
                  path="/user/landing"
                  component={LandingPage}
                />
                <PrivateRoute
                  path="/user/profile"
                  component={UserProfile}
                  exact
                />
                <PrivateRoute
                  path="/user/editprofile"
                  component={EditProfile}
                />

                <Route
                  exact
                  path="/sharesApplication"
                  component={ShareApplication}
                />
                {/* <Route path="/Mentor" component={CompanyPage} /> */}

                {/* Product pages */}
                <Route
                  path="/solutions/personal_services"
                  component={Personal}
                />
                {/* <Route path="/solutions/development_loans_services" component={DevelopmentLoans} />
        <Route path="/solutions/short_term_personal_services" component={ShortTermPersonal} /> */}
                <Route
                  path="/solutions/business_services"
                  component={Business}
                />
                <Route
                  path="/solutions/corporate_services"
                  component={Corporate}
                />
                <Route
                  exact
                  path={"/solution-details"}
                  component={ProductDetailPage}
                />
                <Route
                  exact
                  path={"/solution-categories"}
                  component={MainProductPage}
                />
                <Route
                  exact
                  path={"/product-information"}
                  component={ProductInformation}
                />

                {/* Memberships */}
                {/* <Route path="/membership/tsc" component={TSC} />
        <Route path="/membership/groups_chamas" component={GroupsChamas} />
        <Route path="/membership/businesses_companies" component={BusinessCompanies} />
        <Route path="/membership/businesses" component={BusinessMembership} />
        <Route path="/membership/companies" component={CompanyMembership} /> */}
                <Route
                  path="/membership/terms_conditions"
                  component={TermsConditions}
                />
                {/* <Route path="/membership/employed" component={Employed} /> */}

                {/* <Route path="/membership/tsc_account_creation" component={TSCZero} />
        <Route path="/membership/personal_information" component={TSCOne} />
        <Route path="/membership/next_of_kin" component={TSCTwo} />
        <Route path="/membership/remittance_details" component={TSCThree} />

        <Route path="/business_groups/account_creation" component={BizZero} />
        <Route path="/business_groups/account_operation" component={BizOne} />
        <Route path="/business_groups/remittance_details" component={BizTwo} /> */}

                {/* Memberships */}

                {/* <Route path="/product/loans" component={LoansList} exact />
        <Route path="/product/loan" component={LoanDetail} exact /> */}
                <Route
                  path="/product/loan_estimate"
                  component={LoanEstimate}
                  exact
                />

                {/* News */}
                <Route
                  path="/sacco_news/news_title"
                  component={NewsDetail}
                  exact
                />
                <Route
                  exact
                  path="/sacco_news/press_release"
                  component={PressReleaseComponent}
                />

                {/* Events */}

                <Route path="/events" component={EventList} exact />
                <Route path="/event/details" component={EventDetails} exact />

                {/* Member Registration jioni */}
                <Route
                  exact
                  path="/registration/account_creation_success"
                  component={Confirmation}
                />
                <Route
                  exact
                  path="/user/member_login"
                  component={MemberLogin}
                />
                <Route exact path="/reset_password" component={ResetPassword} />

                {/* Member complete registration */}
                {/* <PrivateRoute exact path='/accountdetails/tsc' component={TSCProfile} /> */}
                <PrivateRoute
                  exact
                  path="/accountdetails/employed"
                  component={TSCProfile}
                />
                {/* <PrivateRoute exact path='/accountdetails/business' component={BuisnessProfile} /> */}
                <PrivateRoute
                  exact
                  path="/accountdetails/groups"
                  component={GroupsProfile}
                />
                {/* <PrivateRoute exact path='/accountdetails/employed' component={EmployedProfile} /> */}

                {/* Successpages */}

                <Route path="/success" component={RegisterSuccess} />

                {/* Footer Links */}

                <Route path="/help/faqs" component={FAQs} />
                <Route path="/news" component={NewsList} exact />
                {/* <Route path="/csr/gallery" component={ImageGallery} exact /> */}
                <Route path="/gallery" component={Gallery} exact />
                <Route path="/location" component={Location} exact />
                {/* <Route path="/dividends" component={Dividends} exact /> */}
                <Route exact path="/careers" component={Careers} />
                <Route exact path={"/contactus"} component={ContactUs} />

                {/* Loan Categories */}
                {/* <Route path="/loans/personal" component={PersonalLoans} exact />
        <Route path="/loans/business" component={BusinessLoans} exact />
        <Route path="/loans/corporate" component={CorporateLoans} exact />
        <Route exact path="/loaninfo/:name" component={LoansProductPage} /> */}
                <Route exact path={"/applyLoan"} component={LoanForm} />

                {/* Insurance Products */}
                {/* <Route path="/insurance/life" component={Life} exact />
        <Route path="/insurance/health" component={Health} exact />
        <Route path="/insurance/auto" component={Auto} exact />
        <Route path="/insurance/education" component={Education} exact />
        <Route path="/insurance/property" component={PropertyInsurance} exact />
        <Route exact path="/insuaranceproduct/:name" component={InsuaranceDetails} /> */}

                {/* Registration Forms */}
                <Route
                  exact
                  path="/mobile_banking/register"
                  component={MobileBanking}
                />
                <Route
                  exact
                  path="/atm_card/register"
                  component={ATMApplication}
                />
                <Route
                  exact
                  path="/fosa_loan/application"
                  component={FosaLoan}
                />
                <Route
                  exact
                  path="/bosa_loan/application"
                  component={BosaLoan}
                />
                <Route
                  exact
                  path="/funds_transfer/application"
                  component={FundsTransfer}
                />
                <Route
                  exact
                  path="/salary_advance/application"
                  component={SalaryAdvance}
                />

                <Route
                  exact
                  path="/standing_order/application"
                  component={StandingOrderVariation}
                />
                <Route
                  exact
                  path="/account_statement_request/application"
                  component={AccountStatementRequest}
                />
                <Route
                  exact
                  path="/joint_account/application"
                  component={JointAccountApplication}
                />
                <Route
                  exact
                  path="/business_account/registration"
                  component={BusinessAccountRegistration}
                />
                <Route
                  exact
                  path="/invest/junior_account"
                  component={JuniorAccountApplication}
                />
                <Route
                  exact
                  path="/invest/holiday_account"
                  component={HolidayAccountApplication}
                />
                <Route
                  exact
                  path="/invest/savings_account"
                  component={FixedDAccountApplication}
                />
                {/* <Route path="/submit/success" component={SuccessSubmit} /> */}

                {/* Our Team */}
                <Route path="/our_team/board_members" component={OurTeam} />

                <Route path="" component={NotFound} />
              </Switch>
              <Footer />
              {/* chatbot component */}
              {chatbotcookie && <ChatBotComponent />}
              {/* cookie consent */}

              {chatbotcookie && showcookie && (
                <CookieConsentComponent setCookie={checkCookie} />
              )}
              {/*  toaster*/}
              <ToastContainerComponent />
            </Router>
          </React.Suspense>
        </ErrorBoundary>
      </AnalyticsContextComponent>
    </div>
  );
}

export default App;

if (process.env.NODE_ENV !== "development") {
  window.console.log = () => {};
}

/*lorem ipsum dolorndjdhfhdfhfhdh
loremshay */
