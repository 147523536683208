import React from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import { useAnalytics } from "./context/analytics.context";

const theme = {
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#067C4B",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#EF6C00",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

export default function ChatBotComponent() {
  const [opened, setOpened] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [username, setName] = React.useState("");
  const [isHidden, setHidden] = React.useState({ hidden: false, show: 1 });
  // fetch both
  const _user = JSON.parse(localStorage.getItem("user"));
  const user = JSON.parse(localStorage.getItem("user"))?.user ?? _user;
  const { createAnalytics } = useAnalytics();

  const handleEnd = (steps, values) => {
    console.log("End game", values, steps, username);
    createAnalytics("Bot session", username, "chatbot");
  };
  React.useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        setHidden({ hidden: true, show: isHidden.show++ });
      }
    });
  }, []);
  React.useEffect(() => {
    const bot = "mentor-bot";
    const isBottted = JSON.parse(localStorage.getItem(bot));
    if (
      isHidden.hidden &&
      isHidden.show === 1 &&
      isBottted === null &&
      user === null
    ) {
      //localStorage.setItem(bot, Date.now().toString());
      //setTimeout(() => setOpened(true), 3000);
    } else setOpened(false);
  }, [isHidden?.hidden]);

  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        opened={opened}
        toggleFloating={() => setOpened(!opened)}
        handleEnd={handleEnd}
        floating={true}
        botAvatar="/favicon.ico"
        headerTitle="Mentor Sacco Chat Bot"
        // style={{background}}
        steps={[
          {
            id: "1",
            message:
              " Hello there!, I am Kim, your chat bot from Mentor Sacco.  ",
            trigger: "2.1",
            delay: 2000,
          },
          {
            id: "2.1",
            message: " Kindly click on the service I may assist you with  ",
            trigger: "2",
            delay: 3000,
          },
          {
            id: "2",

            options: [
              { value: "save", trigger: "save", label: "Save" },
              { value: "borrow", trigger: "borrow", label: "Borrow" },
              { value: "invest", trigger: "invest", label: "Invest" },
              { value: "insure", trigger: "insure", label: "Insure" },
              { value: "sacco", trigger: "sacco", label: "Sacco services" },
              {
                value: "Membership",
                trigger: "membership",
                label: "Membership",
              },
            ],
            delay: 5000,

            //trigger: "3",
          },
          {
            id: "save",
            options: [
              {
                value: "Mentor Holiday Account",
                trigger: "3",
                label: "Mentor Holiday account",
              },
              {
                value: "Mentor Junior  Account",
                trigger: "3",
                label: "Mentor Junior  account",
              },
              {
                value: "Mentor Personal Savings",
                trigger: "3",
                label: "Mentor Personal Savings",
              },
            ],
          },
          {
            id: "borrow",
            options: [
              {
                value: "BOSA",
                trigger: "BOSA",
                label: "BOSA",
              },
              {
                value: "FOSA",
                trigger: "FOSA",
                label: "FOSA",
              },
              {
                value: "Agri Business",
                trigger: "Agri",
                label: "Agri Business",
              },
              {
                value: "Business",
                trigger: "Business",
                label: "Business",
              },
            ],
          },
          {
            id: "BOSA",
            options: [
              {
                value: "Jijenge 25 loan",
                trigger: "3",
                label: "Jijenge 25 loan",
              },
              {
                value: "Imara Loan",
                trigger: "3",
                label: "Imara Loan",
              },
              {
                value: "Boresha Loan",
                trigger: "3",
                label: "Boresha Loan",
              },
              {
                value: "Emergency Loan",
                trigger: "3",
                label: "Emergency Loan",
              },
              {
                value: "Jijenge 19 loan",
                trigger: "3",
                label: "Jijenge 19 loan",
              },
              {
                value: "Normal loan",
                trigger: "3",
                label: "Normal loan",
              },
            ],
          },
          {
            id: "FOSA",
            options: [
              {
                value: "Jambo Fosa",
                trigger: "3",
                label: "Jambo Fosa",
              },
              {
                value: "FOSA pride loan",
                trigger: "3",
                label: "FOSA pride loan",
              },
              {
                value: "Salary in Advance",
                trigger: "3",
                label: "Salary in Advance",
              },
              {
                value: "FOSA Imara",
                trigger: "3",
                label: "FOSA Imara",
              },
              {
                value: "Salary Advance",
                trigger: "3",
                label: "Salary Advance",
              },
              {
                value: "Quick Fix Loan",
                trigger: "3",
                label: "Quick Fix Loan",
              },
            ],
          },
          {
            id: "Agri",
            options: [
              {
                value: "Majani Loan",
                trigger: "3",
                label: "Majani Loan",
              },
            ],
          },
          {
            id: "Business",
            options: [
              {
                value: "Business Loan",
                trigger: "3",
                label: "Business Loan",
              },
              {
                value: "Micro Finance Loan",
                trigger: "3",
                label: "Micro Finance Loan",
              },
            ],
          },
          {
            id: "invest",
            options: [
              {
                value: "Ordinary Deposits",
                trigger: "3",
                label: "Ordinary Deposits",
              },
              {
                value: "FOSA Deposits",
                trigger: "3",
                label: "FOSA Deposits",
              },
              {
                value: "Fixed Deposit Account",
                trigger: "3",
                label: "Fixed Deposit Account",
              },
              {
                value: "Mazao Deposits",
                trigger: "3",
                label: "Mazao Deposits",
              },
              {
                value: "Share Capital",
                trigger: "3",
                label: "Share Capital",
              },
            ],
          },
          {
            id: "insure",
            options: [
              {
                value: "Livestock Insurance",
                trigger: "3",
                label: "Livestock Insurance",
              },
              {
                value: "Motor Insurance",
                trigger: "3",
                label: "Motor Insurance",
              },
              {
                value: "Travel Insurance",
                trigger: "3",
                label: "Travel Insuranc",
              },
              {
                value: "Theft",
                trigger: "3",
                label: "Theft",
              },
              {
                value: "Personal Accident",
                trigger: "3",
                label: "Personal Accident",
              },
              {
                value: "Group Chamas",
                trigger: "3",
                label: "Group Chamas",
              },
            ],
          },
          {
            id: "sacco",
            options: [
              {
                value: "Account Statement Request",
                trigger: "3",
                label: "Account Statement Request",
              },
              {
                value: "ATM/VISA services",
                trigger: "3",
                label: "ATM/VISA services",
              },
              {
                value: "Mobile Banking Services",
                trigger: "3",
                label: "Mobile Banking Services",
              },
              {
                value: "Standing Order Application",
                trigger: "3",
                label: "Standing Order Application",
              },
              {
                value: "Funds Transfer",
                trigger: "3",
                label: "Funds Transfer",
              },
            ],
          },
          {
            id: "membership",
            options: [
              {
                value: "TSC/Civil servants",
                trigger: "3",
                label: "TSC/Civil servants",
              },
              {
                value: "Group/Business",
                trigger: "3",
                label: "Group/Business",
              },
              {
                value: "Employed (Salaried persons)",
                trigger: "3",
                label: "Employed (Salaried persons)",
              },
            ],
          },
          {
            id: "3",
            message: "What is your name?",

            trigger: "3.0",
          },
          {
            id: "3.0",
            validator: function(value) {
              console.log(value);
              if (value) {
                setName(value);
                //setOpened(false);
                return true;
              }
            },
            user: true,
            trigger: "3.1",
          },

          {
            id: "3.1",
            message: "What is your phone number?",

            trigger: "3.10",
          },
          {
            id: "3.10",

            user: true,
            trigger: "4",
          },
          {
            id: "4",
            message: "What is your email address",

            trigger: "4.0",
          },
          {
            id: "4.0",

            user: true,
            trigger: "5",
          },
          {
            id: "5",
            message: `Thank your very much ${username}, we will get back to you soon.`,

            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
}
/**
 * Hello! Thank you for contacting our support. How may I assist you?
 * Kindly enter your email
 *
 */
