import React, { Component } from "react";
import Footer from "./components/Navigation/Footer";
import Image from "./images/404.png";
import WhiteHeader from "../src/components/Navigation/WhiteHeader";
import axios from "axios";
import {
  NetworkOff,
  NetworkOn,
  ShowEToast,
  ShowSToast,
} from "./context/compressor";
import { useAnalytics } from "./context/analytics.context";
import ReactGA from "react-ga";
import moment from "moment";
import { useDispatch } from "react-redux";
import { logoutStateClear } from "./store/slices/AuthSlice";

const createG4 = (action, label, category) => {
  let domain = "mentorsacco";

  if (window && window.location.origin.includes(domain)) {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", process.env.REACT_APP_GA4);
    gtag("event", action, {
      event_category: category,
      value: window.location.pathname,
      event_label: label,
    });
  }
};

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, isOffline: false };
    this.handleOffline = this.handleOffline.bind(this);
    this.checkNetwork = this.checkNetwork.bind(this);
    this.handleOnline = this.handleOnline.bind(this);

    this.counter = 0;
  }

  static getDerivedStateFromError(error) {
    if (error) {
      const message = `${moment(new Date()).format(
        "dddd, MMMM Do YYYY"
      )} (${new Date().toLocaleTimeString()})  
      [Error]:      ${window.location.pathname} (${
        window.location.origin
      })   \n message:   ${error.message}`;

      const isMobile = window.matchMedia("only screen and (max-width:640px)")
        .matches;
      const user_ = JSON.parse(localStorage.getItem("user"));
      const user = JSON.parse(localStorage.getItem("user"))?.user ?? user_;
      const mentor = "mentorsacco";
      if (
        process.env.NODE_ENV !== "development" &&
        window.location.origin.includes(mentor)
      ) {
        createG4(message, "errors", "error boundary");
        ReactGA.event({
          category: "Errors",
          action: message,
          label: `Device: ${isMobile ? "Phone" : "PC"} User: ${
            !!user ? "logged in" : "not logged in"
          }`,
        });
      }

      return { error };
    }
  }
  handleOffline(e, msg) {
    console.info(e, msg, "network handler");
    NetworkOff("You are currently offline.");
    this.setState({ isOffline: true });
  }
  handleOnline(e, msg) {
    if (this.state.isOffline) {
      NetworkOn("You are back online");
      this.setState({ isOffline: false });
    }
  }

  async checkNetwork() {
    const url = process.env.REACT_APP_BASE_URI;
    try {
      const response = await axios.get(url);

      if (response.status > 200) {
        console.info("Check network try over 200");
        console.info(response.status);
        NetworkOff("Your internet connection is unstable");
      }
    } catch (error) {}
  }

  componentDidMount() {
    window.addEventListener("offline", (e) => this.handleOffline(e, "offline"));
    window.addEventListener("online", (e) => this.handleOnline(e, "online"));
    //this.timer = setInterval(this.checkNetwork, 60000 * 2);
  }
  componentWillUnmount() {
    //clearInterval(this.timer);
  }
  render() {
    const { error } = this.state;

    const { children, fallback } = this.props;
    if (error) return fallback(error.message);
    else return children;
  }
}

export const FallBack = ({ message }) => {
  const [isOffline, setOffline] = React.useState(false);
  const user_ = JSON.parse(localStorage.getItem("user"));
  const user = JSON.parse(localStorage.getItem("user"))?.user ?? user_;
  const isReloaded = sessionStorage.getItem("reloaded");
  const time = 4000;

  const dispatch = useDispatch();
  const loadingChunk = "Loading chunk";
  const handleChunk = (message) => {
    if (message.includes(loadingChunk)) {
      // we need to do a hard reload
      if (!isReloaded) {
        // if no reload has taken place, just reload
        ShowSToast("The page will reload for an update");
        setTimeout(() => {
          sessionStorage.setItem("reloaded", "true");
          window.location.reload();
        }, 500);
      } else {
        handlePostHog(loadingChunk);
      }
    }
  };
  React.useEffect(() => {
    checkNetwork();
    handlePostHog();
    handleChunk(message);
  }, []);

  const handlePostHog = (iMessage) => {
    const message = `${moment(new Date()).format(
      "dddd, MMMM Do YYYY"
    )} (${new Date().toLocaleTimeString()})  
      [Error]:      ${window.location.pathname} (${
      window.location.origin
    })   \n message:   ${iMessage}`;

    const isMobile = window.matchMedia("only screen and (max-width:640px)")
      .matches;
    const user_ = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(localStorage.getItem("user"))?.user ?? user_;
    const mentor = "mentorsacco";
    if (
      process.env.NODE_ENV !== "development" &&
      window.location.origin.includes(mentor)
    ) {
    }
  };
  async function checkNetwork() {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URI);

      if (response.status !== 200) {
        ShowEToast(
          "It seems you're offline, kindly check your internet connection"
        );
      }
    } catch (error) {}
  }
  const dims = {
    x: document.documentElement.clientWidth,
    y: document.documentElement.clientHeight,
  };
  const logout = () => {
    const mentor = "mentorsacco";

    if (
      process.env.NODE_ENV !== "development" &&
      window.location.origin.includes(mentor)
    ) {
      ReactGA.event({
        category: "log out reloads",
        action: message,
        label: !!user ? "Logged in" : "Unlogged",
      });
    }

    dispatch(logoutStateClear());
    localStorage.clear();
  };
  React.useEffect(() => {
    return () => {
      if (isReloaded) sessionStorage.removeItem("reloaded");
    };
  }, []);
  const handleError = () => {
    if (isReloaded) {
      if (user) {
        logout();
        window.location.pathname = "/user/member_login";
      } else {
        window.location.href = "/";
      }
      sessionStorage.removeItem("reloaded");
    } else {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    }
  };

  const UserError = (
    <div className="py-3 my-2">
      <h5>
        Oops! Dear {user?.first_name ?? "user"}, we have detected an error from
        our end. Don't fret!,
      </h5>
      {isReloaded ? (
        <div>
          <p>
            The error has persisted, You will now log in again. Kindly click to
            continue.
          </p>
          <button
            style={{ marginLeft: 14, marginTop: ".75rem" }}
            className="btn btn-error ml-3 pl-2"
            onClick={handleError}
          >
            Log out
          </button>
        </div>
      ) : (
        <div>
          <p>
            To resolve this error, kindly refresh by clicking the button below.
          </p>
          <button
            style={{ marginLeft: 14, marginTop: ".75rem" }}
            className="btn btn-error ml-3 pl-2"
            onClick={handleError}
          >
            Refresh page
          </button>
        </div>
      )}
    </div>
  );
  return (
    <div style={boundaryStyles(dims)}>
      <div className="my-auto p-8 mx-auto ">
        {user ? (
          UserError
        ) : (
          <div>
            <h3 className="text-center px-4 py-2 my-2 m-2">
              Oops! Something went Wrong here.... A system error.. but don't
              fret.
            </h3>
            {isReloaded ? (
              <div>
                <p>
                  Go to home (our home page) and try again.
                  <button
                    style={{ marginLeft: 14, marginTop: ".75rem" }}
                    className="btn btn-error ml-3 pl-2"
                    onClick={handleError}
                  >
                    Home
                  </button>
                </p>
              </div>
            ) : (
              <div>
                <p>
                  To resolve this error, kindly reload by clicking the button
                  below.
                </p>
                <button onClick={handleError}>Reload </button>
              </div>
            )}
            <button
              style={{ marginLeft: 14, marginTop: ".75rem" }}
              className="btn btn-error ml-3 pl-2"
              onClick={handleError}
            >
              Home.
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const boundaryStyles = (dims) => {
  return {
    width: dims.x - 10,
    background: `url(${Image}) fixed  no-repeat center / contain`,
    height: dims.y - 10,

    zIndex: 30,
    padding: "4rem",
    display: "flex",
    justifyContent: "center",
  };
};
