import React from "react";
import ReactGA from "react-ga";

const AnalyticsContext = React.createContext({});
export const useAnalytics = () => React.useContext(AnalyticsContext);

export default function AnalyticsContextComponent({ children }) {
  const createG4 = (action, label, category) => {
    let domain = "https://www.mentorsacco.co.ke";

    if (window && window.location.origin === domain) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", process.env.REACT_APP_GA4);
      gtag("event", category + " " + action, {
        event_category: category,
        value: window.location.pathname,
        event_label: label,
      });
    }
  };
  //lets only track for analytics in production
  const createAnalytics = ({ category, action, label = "" }) => {
    if (process.env.NODE_ENV !== "environment") {
      ReactGA.event({
        category,
        action,
        label,
      });
      createG4(action, label, category);
    }
  };

  return (
    <AnalyticsContext.Provider value={{ createAnalytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
