import React from "react";

function GoogleMap() {
  return (
    <iframe
      frameBorder="0"
      title="google"
      style={{ border: 0, width: "85%", height: "20vh", paddingBottom: "10px" }}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9521.061681593019!2d37.15638108191591!3d-0.7215509749924375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182898783c73e921%3A0x894c4a8dd4e987dc!2sMENTOR%20SACCO%20MURANGA!5e0!3m2!1sen!2ske!4v1631889754115!5m2!1sen!2ske"
      allowFullScreen={true}
    ></iframe>
  );
}

export default GoogleMap;
