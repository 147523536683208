/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShowEToast = (e) => {
  toast.error(`${e}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ShowSToast = (m) => {
  toast.success(`${m}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const modifiederror = (e) => {
  if (e.message === "Request failed with status code 401") {
    return "No user with the provided credentials was found. Please check email and passsword again";
  }
  if (e.message === "Network Error") {
    return "Seems like you are not connected to the internet";
  }
  if (e.message === "Request failed with status code 500") {
    return "Something went wrong. Please try again later";
  }
  if (e.message === "Request failed with status code 400") {
    return "Invalid Email Address";
  }
};

export const completeRegistration = createAsyncThunk(
  "group/completeRegistration",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axiosClient.post(
        "/create/MemberAccountDetail/v1",
        {
          ...values.values,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      // console.log('[complete response]', response.data);
      localStorage.setItem("account", JSON.stringify(response.data.account));

      localStorage.removeItem("personalInfo");
      //localStorage.setItem("personalInfo", JSON.stringify(newP_info));
      values.moveToLanding();
      ShowSToast("Account Registaration successfull");
      return response.data;
    } catch (e) {
      console.log("[complete info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      const es = modifiederror(e);
      ShowEToast(es);
      return e.message;
    }
  }
);

export const GroupSlice = createSlice({
  name: "group",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    personalInfo: [],
    groupInfo: {},
    accountInfo: {},
    remittanceInfo: {},
  },
  reducers: {
    addUserInfo: (state, action) => {
      state.personalInfo = action.payload.data;
      //localStorage.setItem('groupPersonalinfo', JSON.stringify(action.payload.data))
      localStorage.removeItem("groupPersonalinfo");
      action.payload.moveToNext(1);
      return state;
    },

    addGroupInfo: (state, action) => {
      state.groupInfo = action.payload.data;
      //localStorage.setItem("groupInfo", JSON.stringify(action.payload.data));
      localStorage.removeItem("groupInfo");
      action.payload.moveToNext(2);
      return state;
    },

    addAccountInfo: (state, action) => {
      state.accountInfo = action.payload.data;
      localStorage.setItem(
        "groupAccountInfo",
        JSON.stringify(action.payload.data)
      );
      action.payload.moveToNext(3);
      return state;
    },
    addRemittanceInfo: (state, action) => {
      state.remittanceInfo = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(completeRegistration.pending, (state) => {
      state.isFetching = true;
      return state;
    }),
      builder.addCase(completeRegistration.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(completeRegistration.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload;
        return state;
      });
  },
});

// export const { submitUserInfo } = TscSlice.actions;

export const {
  addAccountInfo,
  addGroupInfo,
  addUserInfo,
  addRemittanceInfo,
} = GroupSlice.actions;

export const groupSelector = (state) => state.group;
