import axios from "axios";
import { ShowEToast, ShowSToast } from "../context/compressor";
const token = localStorage.getItem("token");

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,

  onUploadProgress: function(e) {},
});
axiosClient.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    //console.log(config);
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    let res = error;
    console.log({ ...error }, "err");
    const invalidToken = "Given token not valid for any token type";
    const otpError = "Kindly send the otp code for verification first";
    if (error.response) {
      if (error.response.status === 401) {
        if ("detail" in error.response.data) {
          if (error.response.data.detail === invalidToken) {
            localStorage.setItem("session_expired", "session");
            window.location.pathname = "/user/member_login";
          } /*else if (error.response.data.detail === otpError) {
            localStorage.setItem("session_expired", "otp");
            window.location.pathname = "/user/member_login";
          }*/
        }
      }
    }
    /*
      if (error.response.status === 401) {
        ShowSToast(
          "You session has expired. You will be logged out shortly. Login to continue."
        );
        setTimeout(() => {
          localStorage.removeItem("account");
          localStorage.removeItem("user");
          window.location.pathname = "/user/member_login";
        }, 3000);
      }*/
    //   if (res.status === 401) {
    //   window.location.href = 'http://localhost:3000/user/member_login';
    // }
    console.error("Looks like there was a problem: ", { ...res });
    return Promise.reject(error);
  }
);

export const getDesignations = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/get/Member/Designations`
    );
    const list = res.data;
    // console.log('d', list)
    return list;
  } catch (e) {
    console.log("error getting designamtions", e);
  }
};
