/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../axios";
import { ShowEToast, ShowSToast } from "../../context/compressor";

export const submitLoanForm = createAsyncThunk(
  "loan/submitLoanForm",
  async (values, thunkAPI) => {
    let businessLoanId = 4;

    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/add/product/application/v1",
        { ...values.data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );

      console.log("[complete response]", response.data);

      const isBusinessLoan = values.data.product_id === businessLoanId;
      if (isBusinessLoan) {
        const productFileFormUrl = `https://mentorsacco.co.ke:8443/${response.data.application_id}`;
        window.open(productFileFormUrl);
        return;
      }

      navigator.vibrate([200, 100, 200]);
      values.SuccessPage();
      return response.data;
    } catch (error) {
      navigator.vibrate([200, 100, 200]);
      if (error.response) {
        thunkAPI.rejectWithValue(error.message);

        ShowEToast(error.response.data.message);
        return error.message;
      }
    }
  }
);
export const addProducts = createAsyncThunk(
  "loan/submitLoanForm",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/add_application_request/v1",
        { ...values.data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      console.log("[complete response]", response.data);
      navigator.vibrate([200, 100, 200]);
      values.SuccessPage();
      return response.data;
    } catch (error) {
      navigator.vibrate([200, 100, 200]);
      if (error.response) {
        thunkAPI.rejectWithValue(error.message);

        ShowEToast(error.response.data.message);
        return error.message;
      }
    }
  }
);
export const getUserLoans = createAsyncThunk(
  "loan/getUserLoans",
  async (values, thunkAPI) => {
    try {
      // console.log('values', values)
      values.showLoading(true);
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/get/user/loans/v1",

        { account_id: values.account_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,

            //  'Access-Control-Allow-Origin': "*"
          },
        }
      );
      //console.log("[complete response]", response.data);
      values.showLoading(false);
      values.setData(response.data);
      return response.data;
    } catch (e) {
      console.log("[submit loan form info Error]", { ...e });
      values.showLoading(false);
      if (e.response) {
        ShowEToast(e.response.data.detail);
      } else if (e.request) {
        ShowEToast(e.request.data.detail);
      } else {
        ShowEToast(e.message);
      }
      thunkAPI.rejectWithValue(e.message);
      return e.message;
    }
  }
);

export const getUserGuaranteedLoans = createAsyncThunk(
  "loan/guaranteedLoans",
  async (values, thunkAPI) => {
    try {
      values.setSpinner(true);
      // console.log('values', values)
      const token = localStorage.getItem("token");

      const response = await axiosClient.get(
        `/lw-v2/get/Member-Gurantors-List/By-MemberID/${values.account_id}`,

        {
          headers: {
            Authorization: `Bearer  ${token}`,

            //  'Access-Control-Allow-Origin': "*"
          },
        }
      );
      console.log("[complete response guaranteed loans]", response.data);
      values.setData(response.data);
      values.setSpinner(false);
      return response.data;
    } catch (e) {
      values.setSpinner(false);
      console.log(
        "[ loan guarante details form info Error]",
        e.response.data.detail
      );
      thunkAPI.rejectWithValue(e.message);
      return Promise.reject(e.response.data.detail);
    }
  }
);

export const getUserGuaranteedRequests = createAsyncThunk(
  "loan/guaranteeRequests",
  async (values, thunkAPI) => {
    try {
      // console.log('values', values)
      values.setSpinner(true);
      const token = localStorage.getItem("token");

      const response = await axiosClient.get(
        `/lw-v2/get/Member/${values.account_id}/Loan-Gurantor-Request/${values.status}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            //  'Access-Control-Allow-Origin': "*"
          },
        }
      );
      // console.log('[complete response]', response.data);
      values.setData(response.data);
      values.setSpinner(!true);
      return response.data;
    } catch (e) {
      values.setSpinner(!true);
      console.log("[ loan guarante details form info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return Promise.reject(e.message);
    }
  }
);

export const sendUserAgreement = createAsyncThunk(
  "loan/loanguaranteeagreement",
  async (values, thunkAPI) => {
    try {
      // console.log('values', values)
      values.setFeedback("", true);
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "lw-v2/change/Loan-Gurantorship/Status-Request",

        {
          guarantor_id: values.guarantor_id,
          status: values.status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            //  'Access-Control-Allow-Origin': "*"
          },
        }
      );
      console.log("[complete response]", response, response.data);
      values.setFeedback(response.data?.guarantor_request, false);
      ShowSToast("Feedback submitted successfull");
      return response.data;
    } catch (e) {
      console.log("[ loan guarante agreement form info Error]", e.message);
      values.setFeedback(0, false);
      thunkAPI.rejectWithValue(e.response.data.message, false);
      ShowEToast("Error submitting feedback, try again later.");
      console.log(e.response);
      return Promise.reject(e.message);
    }
  }
);

export const LoanSlice = createSlice({
  name: "loan",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitLoanForm.pending, (state) => {
      state.isFetching = true;
      return state;
    }),
      builder.addCase(submitLoanForm.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(submitLoanForm.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload;
        return state;
      }),
      builder.addCase(getUserLoans.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getUserLoans.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getUserGuaranteedLoans.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(
        getUserGuaranteedLoans.fulfilled,
        (state, { payload }) => {
          state.isFetching = false;
          state.isSuccess = true;
          // state.data = payload;
          return state;
        }
      ),
      builder.addCase(getUserGuaranteedLoans.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getUserGuaranteedRequests.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(
        getUserGuaranteedRequests.fulfilled,
        (state, { payload }) => {
          state.isFetching = false;
          state.isSuccess = true;
          // state.data = payload;
          return state;
        }
      );
    builder.addCase(
      getUserGuaranteedRequests.rejected,
      (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }
    );
  },
});

export const loanSelector = (state) => state.loan;

export const { clearState } = LoanSlice.actions;

/**
 * Get member Gurantor list
    route: '/api/lw-v2/get/Member-Gurantors-List/By-MemberID/<int:member_id>'
    request type : GET,
    authentication : required,
    response : Array of gurantors set by the member on the profile
  
Add gurantor list from profile :
    Route : '/api/lw-v2/add/Member-Gurantor-List  
    request type : POST,
    request_body : id,gurantor_account_id,member_account_id
    authentication : required,
    response : return the added gurantors
    
Change gurantor list status :
    Route: '/api/lw-v2/change/Member-Gurantor-Status-Request  
    request type : POST,
    request_body : id,status
    status = 0 -> open, 1 -> approved, 2->rejected
    authentication : required,
    response : returns the gurantor
    
get account by account number :
    Route: '/api/lw-v2/get/MemberAccount/By-AccountNumber/<str:account_no>   
    request type : GET,
    authentication : required,
    response : Array of accounts with specified account_no
  
get loan gurantors request for a member by account_id and status 
    route : ' /api/lw-v2/ get/Member/<int:account_id>/Loan-Gurantor-Request/<int:status> 
    request type : GET,
        status = 0 -> open, 1 -> approved, 2->rejected
    authentication : required,
    response : Array of all loan gurantor requests for the specified member  
    
change or update loan guarantorship:
    route:'api/lw-v2/change/Loan-Gurantorship/Status-Request  
    request type : POST,
    request_body : gurantor_id,status
    status = 0 -> open, 1 -> approved, 2->rejected
    authentication : required,
    response : returns the gurantor
 */
