import React from "react";
import { Link } from "react-router-dom";
import GoogleMap from "../GoogleMap";
// import '../index.css';

import twitter from "../../images/social/twitter.svg";
import facebook from "../../images/social/facebook.svg";
import instagram from "../../images/social/instagram.svg";
import linkedin from "../../images/social/linkedin.svg";
import youtube from "../../images/social/youtube.svg";
import { ShowEToast, ShowSToast } from "../../context/compressor";
import axios from "axios";

function Footer() {
  const currentYear = new Date().getFullYear();
  const [showMap, setMap] = React.useState(false);
  const [text, setText] = React.useState("");
  const [spinner, setSpinner] = React.useState(false);
  const sendToServer = async (email) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/add/subscriber/v1`;
    try {
      setSpinner(true);
      const response = await axios.post(url, { email });
      console.log(response);
      ShowSToast(response?.data?.message);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      setText("");

      ShowEToast(error.response?.data?.message);
    }
  };
  const handleSubscription = () => {
    if (!text.trim().length) return;
    if (text.trim().length < 10) ShowEToast("Enter a valid email address");
    else if (!text.trim().includes("@"))
      ShowEToast("Enter a valid email address");
    else sendToServer(text);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", checkArrow);
    setTimeout(() => setMap(true), 5000);
  }, []);

  const checkArrow = (e) => {
    const topicon = document.getElementById("topicon");

    //console.log(window.pageYOffset, window.innerHeight);
  };

  const ArrowTop = () => (
    <img
      alt=""
      style={{
        position: "fixed",
        right: 0,
        bottom: 0,
        width: 120,
        Zindex: 100,
        margin: 10,
        background: "green",
        color: "green",
      }}
      src="/icons/Back to top.svg"
    />
  );

  return (
    <footer className="section-footer">
      {/*<ArrowTop />*/}
      <div className="container">
        <div className="row col-12 pt-3">
          <div className="col-md-3 pt-4r">
            <div className="row">
              {showMap && <GoogleMap />}
              <div>
                <strong className="py-2">Headquarters</strong>
                <p className="py-2">
                  Mentor Complex, Uhuru Highway, <br />
                  Muranga County.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-md-3 pt-4r">                     
                        <div className="row pl-4">
                            <div className="col-12">
                                <strong className="text-center">Quick Links</strong>
                                <ul>
                                    <li className="py-2">
                                        <Link to="/help/faqs">FAQ</Link>
                                    </li>                          
                                    <li className="pb-2">
                                        <Link to="/news">News</Link>
                                    </li>
                                    <li className="pb-2">
                                        <Link to="/events">Events</Link>
                                    </li>
                                    <li className="pb-2">
                                        <Link to ="/csr/gallery">CSR</Link>
                                    </li>
                                    <li className="pb-2">
                                        <Link to="/location">Locations</Link>
                                    </li>
                                    <li className="pb-2">
                                        <Link to="#">Careers</Link>
                                    </li>
                                </ul>                     
                        </div>
                        </div>
                    </div> */}

          <div className="col-md-3 pt-4r">
            <div className="row">
              <strong className="text-left">Quick Links</strong>
              <div className="col-11 py-2">
                <Link to="/help/faqs">FAQ</Link>
              </div>
              <div className="col-11 py-2">
                <Link to="/news">News</Link>
              </div>

              <div className="col-11 py-2">
                <Link to="/events">Events</Link>
              </div>
              <div className="col-11 py-2">
                <Link to="/product/loan_estimate">Loan Calculator</Link>
              </div>
              {/* <div className="col-11 py-2">
                <Link to="/csr/gallery">CSR</Link>
              </div>*/}
              <div className="col-11 py-2">
                <Link to="/location">Locations</Link>
              </div>
              <div className="col-11 py-2">
                <Link to="/careers">Careers</Link>
              </div>
            </div>
          </div>

          <div className="col-md-3 pt-4r">
            <div className="row">
              <a href="/contactus">
                {" "}
                <strong className="text-left">Contact us</strong>
              </a>
              {/* <a href='/contactus'>Contact Us</a> */}
              <div className="col-11 py-2">
                <i className="fas fa-phone mr-5 "></i> +254 111 026 000
              </div>
              <div className="col-11 my-2">
                <i class="fab fa-whatsapp mr-5"></i> +254 704 066 827
              </div>

              <div className="col-11 my-2">
                <i class="fa fa-envelope pr-5"></i>{" "}
                <a
                  href="mailto:info@mentorsacco.co.ke?subject=webform"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  info@mentorsacco.co.ke
                </a>
              </div>
              <div className="col-11 py-2">
                <i class="fa fa-comment-alt pr-2"></i> 21874
              </div>
            </div>
          </div>

          <div className="col-md-3 pt-4r">
            <div className="row">
              <div className="col-12">
                <strong>Newsletter</strong>
                <p>
                  Get the latest updates in your inbox. Don’t worry, we won’t
                  spam you.
                </p>
                {/* <form class="row g-3">
                                    <label for="inputPassword2" class="visually-hidden">Email</label>
                                    <input type="email" class="form-control" id="email" placeholder="Enter your email" />
                                    <button type="submit" class="btn btn-outline-success mb-3">Subscribe</button>
                                </form> */}
                <div class="input-group py-3">
                  <input
                    type="email"
                    autoComplete="email"
                    placeholder="Enter email address"
                    className="form-control"
                    id="newsletter"
                    onChange={(e) => setText(e.target.value)}
                    aria-describedby="newsletter"
                    aria-label="newsletter"
                    disabled={spinner}
                    value={text}
                  />
                  <button
                    className="btn2 btn-outline-primary"
                    type="button"
                    id="newsletter"
                    onClick={handleSubscription}
                    style={{ backgroundColor: "#F5811E" }}
                  >
                    {spinner && (
                      <div
                        class="spinner-border spinner-border-sm text-primary"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                    {spinner ? "Submitting" : "Subscribe"}
                  </button>
                </div>
                <div className="row mr-2 py-3">
                  <div
                    className="col"
                    onClick={() => {
                      window.open(
                        "https://twitter.com/MentorSaccoLtd",
                        "_blank"
                      );
                    }}
                  >
                    <img
                      loading="lazy"
                      className="img-fluid cursor-pointer"
                      src={twitter}
                      alt=""
                    />
                  </div>
                  <div
                    className="col"
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/mentorsaccosociety",
                        "_blank"
                      );
                    }}
                  >
                    <img
                      loading="lazy"
                      className="img-fluid cursor-pointer"
                      src={facebook}
                      alt=""
                    />
                  </div>
                  {/* <div className="col">
                                            <img className="img-fluid" src={instagram} alt=""/>
                                        </div> */}
                  <div
                    className="col"
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/in/mentor-sacco-55461136/",
                        "_blank"
                      );
                    }}
                  >
                    <img
                      loading="lazy"
                      className="img-fluid cursor-pointer"
                      src={linkedin}
                      alt=""
                    />
                  </div>
                  <div
                    className="col"
                    onClick={() => {
                      window.open(
                        "https://www.youtube.com/channel/UC2E21s1CPjrNuyJEXq9WWjA",
                        "_blank"
                      );
                    }}
                  >
                    <img
                      loading="lazy"
                      className="img-fluid cursor-pointer"
                      src={youtube}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row justify-content-center">
                        <strong className="text-center">Contact us</strong>
                        <div className="col-11">
                            <div className="row justify-content-evenly px-3 py-1">
                                <div className="col-11 col-md-6">
                                    <i class="fas fa-phone-alt"></i> +254 111 026 000
                                </div>
                                <div className="col-11 col-md-6">
                                    <i class="fab fa-whatsapp"></i> +254 704 066 827
                                </div>
                            </div>
                            <div className="row justify-content-evenly px-3 py-1">
                                <div className="col-11 col-md-7">
                                    <i class="fa fa-envelope"></i> info@mentorsacco.co.ke
                                </div>
                                <div className="col-11 col-md-5">
                                    <i class="fa fa-comment-alt"></i> 21874
                                </div>
                            </div>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
      <div className="copyright text-center py-1 mt-2r">
        &copy; 1977-{currentYear} <br />
        Mentor Sacco Society. All Rights Reserved
      </div>
      <img
        src="/images/navigate-up-arrow-svgrepo-com.svg"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        style={{
          // position: "fixed",
          /* bottom: 0,
          right: 0,*/
          width: 50,
          height: 50,
          marginRight: 12,
          zIndex: 30,
          transition: "all .5 ease-in",
          color: "#067C4B",
          display: "none",
        }}
        id="topicon"
        alt="X"
      />
    </footer>
  );
}

export default Footer;
