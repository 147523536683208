import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Provider } from "react-redux";
import "typeface-poppins";
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";
import App from "./App";

import AnalyticsContextComponent from "./context/analytics.context";
import AccountCreationContextProvider from "./context/AccountCreation.context";

ReactDOM.render(
  <AccountCreationContextProvider>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </AccountCreationContextProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// app.js
