/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../axios";

export const completeApplication = createAsyncThunk(
  "serviceApplication/completeRegistration",
  async (values, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosClient.post(
        "/something",
        {
          ...values.data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Access-Control-Allow-Origin': "*"
          },
        }
      );
      // console.log('[complete response]', response.data);
      // values.moveToLanding()
      return response.data;
    } catch (e) {
      console.log("[complete info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return e.message;
    }
  }
);

export const getServices = createAsyncThunk(
  "serviceApplication/getServices",
  async ({ saveData }, thunkAPI) => {
    try {
      // const token = localStorage.getItem('token')
      const response = await axiosClient.get(
        "/get/product/headers/data/v1"
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[get services response]', response.data);
      // values.moveToLanding()
      saveData(response.data);
      return response.data;
    } catch (e) {
      console.log("[complete info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return e.message;
    }
  }
);

export const getCategories = createAsyncThunk(
  "serviceApplication/getCategories",
  async ({ id, saveData }, thunkAPI) => {
    // console.log("fetching categories redux.....", saveData, id);
    try {
      // const token = localStorage.getItem('token')
      const response = await axiosClient.post(
        "/get/product/headers/children/data/v1",
        {
          header_id: id,
          isProductType: true,
        }
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[get categories response]', response.data);
      // values.moveToLanding()
      saveData(response.data);
      return response.data;
    } catch (e) {
      console.log("[complete info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return Promise.reject(e.message);
    }
  }
);
// isProductType:true
export const getDetails = createAsyncThunk(
  "serviceApplication/getDetails",
  async ({ id, loans_included, saveData }, thunkAPI) => {
    try {
      // const token = localStorage.getItem('token')

      const response = await axiosClient.post(
        "/get/product/details/data/v1",
        {
          header_id: id,
          isProductType: loans_included,
        }
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[get details response]', response.data);
      // values.moveToLanding()
      saveData(response.data);

      return response.data;
    } catch (e) {
      console.log("[complete info Error for getting details]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return Promise.reject(e.message);
    }
  }
);

export const getSections = createAsyncThunk(
  "serviceApplication/getSections",
  async ({ id, saveData }, thunkAPI) => {
    try {
      // const token = localStorage.getItem('token')
      const response = await axiosClient.get(
        "/get/show/on/navbar/product/selection/v1"
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[get sections response]', response.data);
      // values.moveToLanding()
      saveData(response.data);
      return response.data;
    } catch (e) {
      console.log("[complete info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return e.message;
    }
  }
);

export const ServiceApplicationSlice = createSlice({
  name: "serviceApplication",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    solutions: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(completeApplication.pending, (state) => {
      state.isFetching = true;
      return state;
    }),
      builder.addCase(completeApplication.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(completeApplication.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload;
        return state;
      }),
      builder.addCase(getServices.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getServices.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getServices.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.solutions = payload;
        return state;
      }),
      builder.addCase(getCategories.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getCategories.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getCategories.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        // state.solutions = payload;
        return state;
      }),
      builder.addCase(getDetails.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getDetails.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getDetails.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        // state.solutions = payload;
        return state;
      }),
      builder.addCase(getSections.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getSections.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getSections.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        // state.solutions = payload;
        return state;
      });
  },
});

export const serviceSelector = (state) => state.serviceApplication;
