/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosClient } from '../../axios';

export const completeRegistration = createAsyncThunk(
    'business/completeRegistration',
    async(values, thunkAPI) => {
        try{
            const token = localStorage.getItem('token')
            const response = await axiosClient.post('/create/MemberAccountDetail/v1', 
            {
                ...values.values
            },
            {
            headers: {
                'Authorization' : `Bearer ${token}`,
                // 'Access-Control-Allow-Origin': "*"
              }
            }
            )
            // console.log('[complete response]', response.data);
            localStorage.setItem('account', JSON.stringify(response.data.account))
            // const p_info = JSON.parse(localStorage.getItem('personalInfo'))
            // const newP_info = {...p_info, member_status: 1}
            // localStorage.removeItem('personalInfo')
            // localStorage.setItem('personalInfo', JSON.stringify(newP_info))
            const p_info = JSON.parse(localStorage.getItem('personalInfo'))
            const newP_info = {...p_info, member_status: 1}
            localStorage.removeItem('personalInfo')
            localStorage.setItem('personalInfo', JSON.stringify(newP_info))
            values.moveToLanding()
            return response.data
        }
        catch(e) {
            console.log('[complete info Error]', e.message);
            thunkAPI.rejectWithValue(e.message);
            return e.message
        }
    }
)

export const BuisnessSlice = createSlice({
    name: 'business',
    initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    personalInfo: [],
    buisnessInfo: {},
    accountInfo: {},
    remittanceInfo: {}
    },
    reducers: {
        addUserInfo: (state, action) => {
            state.personalInfo = action.payload.data
            localStorage.setItem('buisnessPersonalinfo', JSON.stringify(action.payload.data))
            action.payload.moveToNext(1)
            return state
        },

        addBuisnessInfo: (state, action) => {
            state.buisnessInfo = action.payload.data
            localStorage.setItem('buisnessInfo', JSON.stringify(action.payload.data))
            action.payload.moveToNext(2)
            return state
        },

        addAccountInfo: (state, action) => {
            state.accountInfo = action.payload.data   
            localStorage.setItem('buisnessAccountInfo', JSON.stringify(action.payload.data))
            action.payload.moveToNext(3)
            return state          
        },
        addRemittance: (state, action) => {
            state.remittanceInfo = action.payload
            return state
        }
    },
    extraReducers: (builder) => {
        builder.addCase(completeRegistration.pending, (state) => {
            state.isFetching = true;
            return state;
        }),
        builder.addCase(completeRegistration.rejected, (state, { payload }) => {
            state.errorMessage = payload;
            state.isError = true;
            state.isFetching = false;
            state.isSuccess = false;
            return state;
        }),
        builder.addCase(completeRegistration.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.data = payload;
            return state;
        })     
    }
          
})

// export const { submitUserInfo } = TscSlice.actions;

export const { addUserInfo, addAccountInfo, addRemittance, addBuisnessInfo } = BuisnessSlice.actions

export const buisnessSelector = state => state.business;