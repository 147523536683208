import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

export default function CookieConsentComponent({ setCookie = (f) => f }) {
  const handleCookie = () => {
    setCookie(true);
  };

  return (
    <CookieConsent
      debug="true"
      location="bottom"
      buttonText="I agree"
      cookieName="mentorSaccoCookie"
      onAccept={handleCookie}
      style={{ background: "#515354" }}
      buttonStyle={{
        background: "#067C4B",
        fontSize: "15px",
        color: "white",
        borderRadius: "0.2rem",
      }}
      expires={150}
    >
      Mentor Sacco is dedicated to upholding data privacy. Our website uses
      cookies to enhance the user experience. By continuing to use this website,
      you agree to our <Link to="">privacy policy.</Link>
    </CookieConsent>
  );
}
