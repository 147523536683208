import React from "react";
import axios from "axios";
import Compressor from "compressorjs";
import validate from "validate.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imageCompression from "browser-image-compression";
import * as Sentry from "@sentry/react";
import { axiosClient } from "../axios";
import SignatureCanvas from "react-signature-canvas";
//import { FiExternalLink } from "react-icons/fi";
import { FiExternalLink } from "react-icons/fi";
import { useAnalytics } from "./analytics.context";
import { Button } from "react-bootstrap";
import Webcam from "react-webcam";
//npm install browser-image-compression --save
export default function fileCompressor(file) {
  new Compressor(file, {
    quality: 0.6,

    // The compression process is asynchronous,
    // which means you have to access the `result` in the `success` hook function.
    success(result) {
      const formData = new FormData();

      // The third parameter is required for server
      formData.append("file", result, result.name);

      // Send the compressed image file to server with XMLHttpRequest.
      /* axios.post("/path/to/upload", formData).then(() => {
        console.log("Upload success", result);
      });*/
    },
    error(err) {
      console.log(err.message);
    },
  });
}

export async function handleImageUpload(
  imageFile,
  setSuccess = (f) => f,
  setLoading = (f) => f,
  loading = true
) {
  setLoading(true);
  //var imageFile = event.target.files[0];

  var controller = new AbortController();
  const checkResult = () => {
    if (loading) {
      console.log("loading....");
      // handleImageUpload(imageFile, setLoading, setSuccess);
      controller.abort(new Error("I just want to stop"));
    }
  };
  var options = {
    // other options here
    signal: controller.signal,
    maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 960,
    initialQuality: 1, // optional, initial quality value between 0 and 1 (default: 1)
    alwaysKeepResolution: true,
    useWebWorker: false,
  };
  try {
    setTimeout(checkResult, 30000);
    const res = await imageCompression(imageFile, options);

    const reader = new FileReader();

    reader.onloadend = () => {
      setLoading(false);
      setSuccess(reader.result);

      controller.abort();
    };
    reader.onerror = (error) => {
      throw Error(error?.message);
    };
    reader.readAsDataURL(res);
  } catch (error) {
    setLoading(false);
  }

  /*
  imageCompression(imageFile, options)
    .then(function(compressedFile) {
      console.log(compressedFile, "compess");
      //return uploadToServer(compressedFile); // write your own logic
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          if (imageFile) {
            reader.readAsDataURL(imageFile);
          }
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
    })
    .catch(function(error) {
      console.log(error.message); // output: I just want to stop
    });
*/
  // simulate abort the compression after 1.5 seconds
  setTimeout(function() {
    controller.abort(new Error("I just want to stop"));
  }, 1500);
}

//imageCompression.getDataUrlFromFile(file: File): Promise<base64 encoded string>
//imageCompression.getFilefromDataUrl(dataUrl: string, filename: string, lastModified?: number): Promise<File>
//imageCompression.loadImage(url: string): Promise<HTMLImageElement>

/**
 * const options: Options = { 
  maxSizeMB: number,            // (default: Number.POSITIVE_INFINITY)
  maxWidthOrHeight: number,     // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
                                // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
                                // Please check the Caveat part for details.
  onProgress: Function,         // optional, a function takes one progress argument (percentage from 0 to 100) 
  useWebWorker: boolean,        // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

  signal: AbortSignal,          // options, to abort / cancel the compression

  // following options are for advanced users
  maxIteration: number,         // optional, max number of iteration to compress the image (default: 10)
  exifOrientation: number,      // optional, see https://stackoverflow.com/a/32490603/10395024
  fileType: string,             // optional, fileType override e.g., 'image/jpeg', 'image/png' (default: file.type)
  initialQuality: number,       // optional, initial quality value between 0 and 1 (default: 1)
  alwaysKeepResolution: boolean // optional, only reduce quality, always keep width and height (default: false)
}
 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function compressImgUrl(filename, user, callback = (f) => f) {
  var controller = new AbortController();
  var options = {
    // other options here
    signal: controller.signal,
    maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 960,
    initialQuality: 1, // optional, initial quality value between 0 and 1 (default: 1)
    alwaysKeepResolution: true,
    useWebWorker: false,
  };
  try {
    const res = await fetch(filename);

    let blob = await res.blob();

    const image = await imageCompression(blob, options);
    //callback(image, user);

    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onload = () => {
      callback(reader.result, user, false);
    };
    reader.onerror = (error) => console.log(error.message);
  } catch (error) {
    console.log(error.message);
    callback(error.message, user, true);
  }
}

export const handleUserAferCompression = (newImage, user) => {
  const person = {
    ...user.personal_info,
    photo_url: newImage,
  };

  const TransformedUser = {
    ...user,
    personal_info: { ...user.personal_info, photo_url: newImage },
  };
  /*
  localStorage.setItem("token", access);
  localStorage.setItem("refresh_token", refresh);
  localStorage.setItem("personalInfo", JSON.stringify(person));
  localStorage.setItem("user", JSON.stringify(TransformedUser));
  localStorage.setItem("account", JSON.stringify(!!account ? account : null));*/
};

export const ShowEToast = (e) => {
  toast.error(`${e}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const ShowSToast = (m) => {
  toast.success(`${m}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const NetworkOff = (message) => {
  toast.error(`${message}`, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: "ntworkOff",
    className: "off-toaster",
  });
};

export const NetworkOn = (message) => {
  toast.success(`${message}`, {
    position: "bottom-left",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: "ntworkOn",
  });
};
const modifiederror = (e) => {
  if (e.message === "Request failed with status code 401") {
    return "No user with the provided credentials was found. Please check email and passsword again";
  }
  if (e.message === "Network Error") {
    return "Seems like you are not connected to the internet";
  }
  if (e.message === "Request failed with status code 500") {
    return "An error occured. Please try again later";
  }
  if (e.message === "Request failed with status code 400") {
    return "Invalid Email Address";
  }
};

export const GenderComponent = ({
  gender,
  setGender,
  classnames = "col-12 py-1  col-md-9",
}) => {
  const genderList = [
    { id: 1, value: "Female" },
    { id: 2, value: "Male" },
    { id: 3, value: "Other" },
  ];

  return (
    <div className="row my-3 ">
      <div className={"col-12 py-1  col-md-1"}>
        <label for="gender-profile" className="col-form-label py-1">
          Gender
        </label>
      </div>

      <div className={classnames}>
        <select
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          id="gender-profile"
          style={{
            color: "#263238",
            fontWeight: "bold",
          }}
          className="form-select py-3"
        >
          <option disabled selected>
            Select gender
          </option>
          {genderList.map((gl) => {
            return (
              <option key={gl.id} value={gl.value}>
                {gl.value}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
export const PasswordRequirements = ({ pass }) => {
  const [errors, setErrors] = React.useState([]);
  // initialize some regex
  validate.validators.regex = function(value, options, key, attributes) {
    let regExp = new RegExp(options.pattern);

    if (!regExp.test(value)) {
      return options.message;
    }
  };
  let constraints = {
    password: {
      length: {
        minimum: 8,
        message: "must be at least 8 characters",
      },
      presence: true,
      regex: {
        pattern: "(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])",
        message:
          " should be eight or more characters, with at least one lowercase,  one capital letter and a number.",
      },
    },
  };

  React.useEffect(() => {
    const result = validate({ password: pass }, constraints);

    if (result) setErrors(result.password);
    else setErrors([]);
  }, [pass]);
  if (errors.length > 0)
    return (
      <ul className="list-group my-2 " style={{ listStyle: "none" }}>
        {errors.map((err) => (
          <li class="text-danger" key={err}>
            {err}
          </li>
        ))}
      </ul>
    );
  else return null;
};

export const MatchPasswords = (p1, p2) => {
  if (p1 !== p2) return <p className="text-danger">Passwords do no match</p>;
  else return null;
};

export const WebOTP = (callback) => {
  console.info("OTP  function called");

  if ("OTPCredential" in window) {
    window.addEventListener("load", (e) => {
      const input = document.querySelector(
        'input[autocomplete="one-time-code"]'
      );

      if (!input) return;
      const ac = new AbortController();
      /*
      const form = input.closest("form");
      if (form) {
        form.addEventListener("submit", (e) => {
          ac.abort();
        });
      }*/

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          input.value = otp.code;
          //if (form) form.submit();
          console.log("ot vince 1", otp);
          alert(`OTP code:`, otp.code);
          callback(otp.code);
        })
        .catch((err) => {
          console.info(err, "ot vince 1");
        });
    });
  } else console.info("No otp support");

  // part b
  if ("OTPCredential" in window) {
    const ac = new AbortController();
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        //input.value = otp.code;
        //if (form) form.submit();
        console.info("ot vince 2", otp);
        alert(`OTP code unloaded:`, otp.code);
        callback(otp.code);
      })
      .catch((err) => {
        console.info(err, "ot vince 2");
      });
  }
};

export const validateAccountNumber = async (accountNumber, setSpinner) => {
  const token = localStorage.getItem("token");
  try {
    setSpinner(true);
    let url = `${process.env.REACT_APP_API_BASE_URL}/lw-v2/get/MemberAccount/By-AccountNumber/${accountNumber}`;
    const response = await axiosClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
    });
    const {
      data: { accounts, personal },
    } = response;
    setSpinner(false);

    if ("id" in accounts)
      return {
        ...personal,
        email: accounts.email,
        name: accounts.name,
        accountId: accounts.id,
        accountStatus: accounts.account_status,
      };
    else return null;
  } catch (error) {
    setSpinner(false);
    console.log(error.response);
  }
};

export const validateUserOTP = async (payload, setSpinner = (f) => f) => {
  try {
    setSpinner(true);
    let url = `${process.env.REACT_APP_API_BASE_URL}/verify/First-Time-Login/User/v1`;
    const response = await axios.post(url, payload);

    if (response?.data === "success") setSpinner(false);
    return true;
  } catch (error) {
    return false;
  }
};

export const MentorPagination = ({ pages, current }) => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-link">Previous</li>
        {[...Array(pages)].map((item, i) => (
          <li key={i} className="page-item">
            <a className="page-link" href="#" tabindex="-1">
              {i + 1}
            </a>
          </li>
        ))}
        <li className="page-item">
          {" "}
          <a className="page-link" href="#">
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};
export const MentorSpinner = ({ message = "" }) => {
  return (
    <div className="d-flex justify-content-center alert alert-info">
      <div
        className="spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span className="visually-hidden ml-3">Loading...</span>
      </div>

      <span className="px-2"> {message}</span>
    </div>
  );
};
export const MentorSecurity = () => {
  return (
    <div className=" card p-3 mt-3" style={{ color: "#067c4b" }}>
      <small className="text-center text-danger">
        Security starts with you... Beware of fraudsters.
      </small>
      <p>1. Do not reveal your password to anyone.</p>
      <p>
        2. Mentor Sacco Society Limited staff will never call to ask for your
        online account password or account number.
      </p>
      <p>
        3. Ensure your log out your account on shared devices, workspaces i,e
        cyber cafes.
      </p>
      <p>
        {" "}
        4. Mentor Sacco official telephone lines are: <b>
          +254 111 026 000
        </b>{" "}
        and <b>+254 704 066 827</b>
      </p>
    </div>
  );
};
export const getMemberAccount = async (accountId, setSpinner = (f) => f) => {
  const token = localStorage.getItem("token");
  try {
    setSpinner(true);
    let url = `${process.env.REACT_APP_API_BASE_URL}/get/MemberAccount/ByAccountID/${accountId}`;

    const response = await axiosClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
    });
    const { data } = response;
    setSpinner(false);

    if (data) return data;
    else return null;
  } catch (error) {
    setSpinner(false);
    console.log(error.response);
  }
};

export const getMemberInfo = async (setSpinner = (f) => f) => {
  try {
    setSpinner(true);
    const token = localStorage.getItem("token");
    const response = await axiosClient.get("/get/user", {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Access-Control-Allow-Origin': "*"
      },
    });
    let { data: user } = response;

    setSpinner(false);

    if (user) return user;
    else return null;
  } catch (error) {
    setSpinner(false);
    console.log(error.response);
  }
};
export const UserSignature = ({
  setSignature = (f) => f,
  setManual = (f) => f,
}) => {
  const sign = React.useRef(null);
  const { createAnalytics } = useAnalytics();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (sign?.current) {
      const URL = sign?.current.getTrimmedCanvas().toDataURL("image/png");

      setSignature(URL);
      createAnalytics("Digital signature", "signature", "sign");
      //sign.current = null;
    }
  };
  React.useEffect(() => {
    return () => {
      if (sign.current) sign.current = null;
    };
  }, []);
  return (
    <div className="mentor-sign">
      <div
        style={{
          background: "beige",
          display: "flex",
          flexDirection: "column",
          padding: 16,
        }}
        className="w-full sm:w-80 "
      >
        {" "}
        <SignatureCanvas
          backgroundColor="white"
          ref={sign}
          velocityFilterWeight={0.3}
          canvasProps={{
            height: 125,
            border: "1px solid black",
            // width: 320,
            className: "signature w-full sm:w-80  ",
          }}
        />
        <small style={{ borderTop: "2px dotted black" }} className="pt-1 pl-2">
          Kindly sign above using mouse or touch device.{" "}
        </small>
      </div>
      <div className=" mentor-sign-btn px-4 py-2 mb-4  ">
        <button
          onClick={handleSubmit}
          style={{ background: "rgba(6, 124, 75, 1)" }}
          className=" btn btn-sm lowercase  text-white w-11/12 sm:w-1/2"
        >
          Submit
        </button>
        <button
          className="btn btn-sm lowercase  w-11/12 sm:w-1/2"
          style={{
            border: "2px solid rgba(6, 124, 75, 1)",
            background: "none",
            color: "black",
          }}
          onClick={(e) => {
            e.preventDefault();
            if (sign.current) sign?.current?.clear();

            setSignature("");
          }}
        >
          Clear
        </button>
      </div>
      <small
        style={{ color: "blue", cursor: "pointer" }}
        className="pt-4"
        onClick={() => setManual(false)}
      >
        Upload signature <FiExternalLink />
      </small>
    </div>
  );
};

export function hasGetUserMedia() {
  return !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  );
}
export const UserCameraPhoto = ({
  setPhoto = (f) => f,
  setWebcam = (f) => f,
  uploadManually = (f) => f,
}) => {
  const [image, setImage] = React.useState("");
  const { createAnalytics } = useAnalytics();
  const handleSubmit = (e) => {
    e.preventDefault();

    const video = document.querySelector(".my-video");
    stopStreamedVideo(video);
    //stopCamera();
    if (image) {
      setPhoto(image);
      createAnalytics("webcam", "webcam", "sign");
      setWebcam(false);
    }
  };

  function stopStreamedVideo(videoElem) {
    const stream = videoElem.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });

    videoElem.srcObject = null;
  }
  const pickRef = React.useRef(null);
  const takePic = React.useCallback(() => {
    new Audio("/camera-shutter-click-01.mp3").play();

    const imageSrc = pickRef?.current.getScreenshot();
    setTimeout(() => setImage(imageSrc), 500);
    setTimeout(() => {
      const pic = document.getElementById("mentor-pic");
      pic.scrollIntoView();
    }, 600);
  }, [pickRef]);
  return (
    <div className="p-4">
      <div className="d-flex justify-content-end">
        <span className="close" onClick={() => setWebcam(false)}>
          X
        </span>
      </div>
      <div className="">
        {" "}
        <small>Ensure you are on a bright background</small>
        <Webcam
          height={400}
          // audio
          width={300}
          ref={pickRef}
          //mirrored
          style={{
            border: "3px solid rgba(20001,128,61,.8)",
            width: "100%",
            minHeight: "100%",
          }}
          className="my-video"
          videoConstraints={{ facingMode: "user" }}
        />
      </div>
      <div>
        <div className="d-flex justify-content-center gap-4 my-2">
          {" "}
          <button
            className=""
            onClick={takePic}
            style={{
              outline: "none",
              border: "1px solid #067c4b",
              borderRadius: 10,
            }}
          >
            {image?.length > 0 ? "Retake" : "Take"} Picture
          </button>
        </div>{" "}
        {image && (
          <small className="mt-2 pt-2">
            {" "}
            <b>Preview.</b> Feel free to retake more.
          </small>
        )}
        {image && (
          <div>
            <img
              src={image}
              alt=""
              id="mentor-pic"
              className="my-2  transition-all duration-400 ease-in animate-pulse"
            />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-evenly align-items-center  py-2 gap-4">
        <span
          onClick={uploadManually}
          className="py-4"
          style={{ color: "blue", cursor: "pointer" }}
        >
          {" "}
          upload manually
        </span>
        <button
          className="btn btn-md py-0 "
          onClick={handleSubmit}
          disabled={image?.length === 0}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export const SentryMessage = (message) => {
  Sentry.captureMessage(message);
};
