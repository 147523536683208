import React, { useEffect } from "react";
import Loader from "react-loader-spinner";

export default function Loading({ message = "Just a moment..." }) {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        paddingTop: "4rem",
      }}
    >
      <Loader type="Oval" color="#067c4b " height={200} width={200} />
      <div className="py-5">{message}</div>
    </div>
  );
}

export const SmallerLoader = ({ message = "Just a moment..." }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: "6rem",
        margin: "auto",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Loader type="Oval" color="#067c4b " height={100} width={100} />
      <div className="py-5">{message}</div>
    </div>
  );
};
