/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../axios";

export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (values, thunkAPI) => {
    try {
      const response = await axiosClient.get("/get/Events/Blogs/v1");
      // console.log('[events response]', response.data.events);

      return response.data.events;
    } catch (e) {
      console.log("[get events Error]", e.message);
      thunkAPI.rejectWithValue(e.message);

      return Promise.reject(e.message);
    }
  }
);
export const getBlogs = createAsyncThunk(
  "events/getBlogs",
  async (values, thunkAPI) => {
    try {
      const response = await axiosClient.get(
        "/get/Events/Blogs/v1"
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[events response]', response.data.events);
      return response.data.blogs;
    } catch (e) {
      console.log("[get blogs Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return Promise.reject(e.message);
    }
  }
);
export const getPressR = createAsyncThunk(
  "events/getPressR",
  async (values, thunkAPI) => {
    try {
      const response = await axiosClient.get(
        "/get/Events/Blogs/v1"
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[events response]', response.data.events);
      return response.data.press_release;
    } catch (e) {
      console.log("[get pressr Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return Promise.reject(e.message);
    }
  }
);

export const getDownloads = createAsyncThunk(
  "events/getDownloads",
  async (values, thunkAPI) => {
    try {
      const response = await axiosClient.get(
        "/get/download/center"
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[downloads response]', response.data);
      return response.data;
    } catch (e) {
      console.log("[get downloads Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      return Promise.reject(e.message);
    }
  }
);

export const registerEvent = createAsyncThunk(
  "events/registerEvent",
  async (values, thunkAPI) => {
    try {
      const response = await axiosClient.post(
        "/register_event/v1",
        {
          event_id: values.data.eventID,
          account_number: values.data.accountNo,
        }
        // {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*"
        //     }
        // }
      );
      // console.log('[events response]', response.data);
      values.data.close();
      values.data.success();
      return response.data;
    } catch (e) {
      console.log("[registartion info Error]", e.message);
      thunkAPI.rejectWithValue(e.message);
      values.toggleError();
      return Promise.reject(e.message);
    }
  }
);

export const EventSlice = createSlice({
  name: "events",
  initialState: {
    user: {},
    eventData: [],
    blogsData: [],
    pressRData: [],
    downloads: [],
    isFetching: false,
    isError: false,
    isSuccess: false,
    errorMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvents.pending, (state, { payload }) => {
      state.isFetching = true;
      return state;
    }),
      builder.addCase(getEvents.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.eventData = payload;
        return state;
      }),
      builder.addCase(getEvents.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(registerEvent.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(registerEvent.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(registerEvent.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.eventData = payload;
        return state;
      }),
      builder.addCase(getPressR.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getPressR.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getPressR.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.pressRData = payload;
        return state;
      }),
      builder.addCase(getBlogs.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getBlogs.rejected, (state, { payload }) => {
        console.log("rejected blog", payload);
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getBlogs.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.blogsData = payload;
        return state;
      }),
      builder.addCase(getDownloads.pending, (state) => {
        state.isFetching = true;
        return state;
      }),
      builder.addCase(getDownloads.rejected, (state, { payload }) => {
        state.errorMessage = payload;
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        return state;
      }),
      builder.addCase(getDownloads.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.downloads = payload;
        return state;
      });
  },
});

export const eventSelector = (state) => state.events;
